import React, { useCallback, useEffect, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { FaCashRegister } from 'react-icons/fa';
import { Check, Close } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { ButtonEditar, ButtonLancar, HeaderTitulos } from '../DadosJuridicosStyle';
import Grid24 from '../../../../components/UI/Grid24';
import FormWrapperWithTitle from '../../../../components/UI/Wrapper/Form/FormWrapperWithTitle';
import SwitchUI from '../../../../components/UI/Switch/SwitchUI';
import Editor from '../../../../components/UI/EditorTexto';
import MoneyField from '../../../../components/UI/Field/Money/MoneyField';
import { getRequiredLabel } from '../../../../helpers';
import OptionDialogNew from '../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';
import { findDadosJuridicos, lancarCausaDadosJuridicos, salvarInformacoesDadosJuridicos } from '../../../../services/core/processos/processoDadosJuridicosService';
import TooltipUI from '../../../../components/UI/Tooltip/Tooltip';

function InformacoesComponent({
  idProcesso, updatePage, cobrancaJudicial, hasPermissoes,
}) {
  const [estarLancadoValorCausaInicial, setEstarLancadoValorCausaInicial] = useState(false);
  const [estarLancadoValorCausaFinal, setEstarLancadoValorCausaFinal] = useState(false);
  const [estarComAdvogadoExterno, setEstarComAdvogadoExterno] = useState(false);
  const [porcentagemHonorarioContratualInicial, setPorcentagemHonorarioContratualInicial] = useState(0);
  const [porcentagemHonorarioContratualFinal, setPorcentagemHonorarioContratualFinal] = useState(0);
  const [detalhe, setDetalhe] = useState('');
  const [valorCausa, setValorCausa] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [optionalDialogLancarInicial, setOptionalDialogLancarInicial] = useState(false);
  const [optionalDialogLancarFinal, setOptionalDialogLancarFinal] = useState(false);

  const updatePageInformacoes = useCallback(async () => {
    updatePage();

    findDadosJuridicos(idProcesso)
      .then(
        (response) => {
          setEstarLancadoValorCausaInicial(response.data.estarLancadoValorCausaInicial);
          setEstarLancadoValorCausaFinal(response.data.estarLancadoValorCausaFinal);
          setEstarComAdvogadoExterno(response.data.estarComAdvogadoExterno);
          setPorcentagemHonorarioContratualInicial(response.data.porcentagemHonorarioContratualInicial);
          setPorcentagemHonorarioContratualFinal(response.data.porcentagemHonorarioContratualFinal);
          setDetalhe(response.data.detalhesValorCausa);
          setValorCausa(response.data.valorCausa);
        },
      );
  }, [updatePage,
    setEstarLancadoValorCausaInicial,
    setEstarLancadoValorCausaFinal,
    setEstarComAdvogadoExterno,
    setDetalhe,
    setValorCausa,
    setPorcentagemHonorarioContratualInicial,
    setPorcentagemHonorarioContratualFinal,
    idProcesso]);

  const onClickButtonLancarInicial = useCallback(async () => {
    try {
      await lancarCausaDadosJuridicos(false,
        valorCausa,
        detalhe,
        estarComAdvogadoExterno,
        idProcesso).then((response) => {
        if (response.data.codResult === 0) {
          toast.success('Valor enviado com sucesso.');
        } else {
          toast.warn(response.data.msgResult);
        }
      });
    } catch {
      toast.error('Não foi possível enviar o valor dos dados jurídicos. Tente novamente.', { style: { width: '392px' } });
    } finally {
      setOptionalDialogLancarInicial(false);
      updatePageInformacoes();
    }
  }, [detalhe, estarComAdvogadoExterno, valorCausa, idProcesso, updatePageInformacoes]);

  const onClickButtonLancarFinal = useCallback(async () => {
    try {
      await lancarCausaDadosJuridicos(true,
        valorCausa,
        detalhe,
        estarComAdvogadoExterno,
        idProcesso).then((response) => {
        if (response.data.codResult === 0) {
          toast.success('Valor enviado com sucesso.');
        } else {
          toast.warn(response.data.msgResult);
        }
      });
    } catch {
      toast.error('Não foi possível enviar o valor dos dados jurídicos. Tente novamente.', { style: { width: '392px' } });
    } finally {
      setOptionalDialogLancarFinal(false);
      updatePageInformacoes();
    }
  }, [detalhe, estarComAdvogadoExterno, valorCausa, idProcesso, updatePageInformacoes]);

  const onClickConfirmarInformacoes = useCallback(async () => {
    try {
      await salvarInformacoesDadosJuridicos(valorCausa,
        detalhe,
        estarComAdvogadoExterno,
        idProcesso);
      setIsEditing(!isEditing);
      toast.success('Informações atualizadas com sucesso.');
    } catch {
      setIsEditing(!isEditing);
      toast.error('Não foi possível salvar as informações dos dados jurídicos. Tente novamente.', { style: { width: '392px' } });
    }
  }, [detalhe, estarComAdvogadoExterno, valorCausa, isEditing, idProcesso]);

  const onClickCancelarInformacoes = useCallback(() => {
    setIsEditing(!isEditing);
  }, [isEditing]);

  useEffect(() => {
    findDadosJuridicos(idProcesso)
      .then(
        (response) => {
          setEstarLancadoValorCausaInicial(response.data.estarLancadoValorCausaInicial);
          setEstarLancadoValorCausaFinal(response.data.estarLancadoValorCausaFinal);
          setEstarComAdvogadoExterno(response.data.estarComAdvogadoExterno);
          setPorcentagemHonorarioContratualInicial(response.data.porcentagemHonorarioContratualInicial);
          setPorcentagemHonorarioContratualFinal(response.data.porcentagemHonorarioContratualFinal);
          setDetalhe(response.data.detalhesValorCausa);
          setValorCausa(response.data.valorCausa);
        },
      );
  }, [setEstarLancadoValorCausaInicial,
    setEstarLancadoValorCausaFinal,
    setEstarComAdvogadoExterno,
    setDetalhe,
    setValorCausa,
    setPorcentagemHonorarioContratualInicial,
    setPorcentagemHonorarioContratualFinal,
    idProcesso]);

  return (
    <>
      <OptionDialogNew
        open={optionalDialogLancarInicial}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={() => { setOptionalDialogLancarInicial(false); }}
        onClickConfirm={onClickButtonLancarInicial}
        text="Confirma o lançamento do valor do honorário contratual inicial na Conta Corrente do Cliente?"
      />
      <OptionDialogNew
        open={optionalDialogLancarFinal}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={() => { setOptionalDialogLancarFinal(false); }}
        onClickConfirm={onClickButtonLancarFinal}
        text="Confirma o lançamento do valor do honorário contratual final na Conta Corrente do Cliente?"
      />
      <Grid24 container isFormContainer>
        <FormWrapperWithTitle title="Informações">
          <Grid24 container isFormContainer spacing={1}>
            <div style={{
              display: 'flex',
              width: '100%',
              paddingLeft: '4px',
            }}
            >
              <div style={{
                width: '300px',
              }}
              >
                <SwitchUI
                  label="Advogado Externo Envolvido"
                  name="estarComAdvogadoExterno"
                  checked={estarComAdvogadoExterno}
                  disabled={!isEditing}
                  onChange={() => { setEstarComAdvogadoExterno(!estarComAdvogadoExterno); }}
                />
              </div>
              <div style={{ width: '100%', marginRight: '10px' }}>

                {!isEditing ? (
                  <HeaderTitulos>
                    <TooltipUI title="Editar">
                      <ButtonEditar
                        variant="outlined"
                        onClick={() => { setIsEditing(!isEditing); }}
                        disabled={estarLancadoValorCausaInicial || estarLancadoValorCausaFinal || !cobrancaJudicial || !hasPermissoes}
                      >
                        <EditIcon />
                      </ButtonEditar>
                    </TooltipUI>
                  </HeaderTitulos>
                ) : (
                  <HeaderTitulos>
                    <TooltipUI title="Cancelar">
                      <ButtonEditar
                        variant="outlined"
                        onClick={onClickCancelarInformacoes}
                      >
                        <Close />
                      </ButtonEditar>
                    </TooltipUI>
                    <TooltipUI title="Salvar">
                      <ButtonEditar
                        variant="outlined"
                        onClick={onClickConfirmarInformacoes}
                      >
                        <Check />
                      </ButtonEditar>
                    </TooltipUI>
                  </HeaderTitulos>
                )}

              </div>
            </div>
            <Grid24 item xs={24} sm={24}>
              <Editor
                label="Detalhe do Processo Junto ao Tribunal"
                isInputLabel
                readOnly={!isEditing}
                onChange={(value) => { setDetalhe(value); }}
                value={detalhe}
              />
            </Grid24>
            <div style={{
              display: 'flex',
              marginTop: '10px',
              paddingLeft: '4px',
            }}
            >
              <MoneyField
                isForm
                fullWidth
                disabled={!isEditing}
                label={getRequiredLabel('Valor da Causa', true)}
                name="valorCausa"
                value={valorCausa || 0}
                onChange={(e) => { setValorCausa(e.target.value); }}
              />
              {!isEditing && (
                <>
                  <ButtonLancar
                    variant="outlined"
                    onClick={() => { setOptionalDialogLancarInicial(true); }}
                    style={{ marginLeft: '10px' }}
                    disabled={estarLancadoValorCausaInicial
                      || !cobrancaJudicial
                      || !hasPermissoes
                      || porcentagemHonorarioContratualInicial === 0.0}
                  >
                    <FaCashRegister />
                    <span>Lançar Honorários Iniciais</span>
                  </ButtonLancar>
                  <ButtonLancar
                    variant="outlined"
                    onClick={() => { setOptionalDialogLancarFinal(true); }}
                    style={{ marginLeft: '10px' }}
                    disabled={estarLancadoValorCausaFinal
                      || !cobrancaJudicial
                      || !hasPermissoes
                      || porcentagemHonorarioContratualFinal === 0.0}
                  >
                    <FaCashRegister />
                    <span>Lançar Honorários Finais</span>
                  </ButtonLancar>
                </>
              )}

            </div>

          </Grid24>
        </FormWrapperWithTitle>
      </Grid24>
    </>
  );
}

export const Informacoes = InformacoesComponent;
