import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import useStyles from './resumo.styles';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import WrapperSubTitleForm from '../../../components/UI/WrapperSubTitleForm/WrapperSubTitleForm';
import { blue, warmGrey } from '../../../helpers/constants/colors.constants';
import InputField from '../../../components/UI/Field/Input/InputField';
import { getDateTimeMask, getMoneyMask } from '../../../helpers/utils/utils';
import TooltipUI from '../../../components/UI/Tooltip/Tooltip';
import { resumoProcessoController as controller } from './resumoController';
import { atualizarEndossanteDoProcessoService } from '../../../services/core/processos/processosService';

function ResumoProcessoComponent({
  processo, setLoadings, dadosProcesso, disableEditarEndossante = false,
}) {
  const styles = useStyles();
  const utilStyles = useUtilStyles();

  const [isSalvarCancelar, setIsSalvarCancelar] = useState(false);
  const [endossanteValue, setEndossanteValue] = useState(undefined);
  const [endossanteOld, setEndossanteOld] = useState(undefined);

  const naoTemPermissaoEndossante = useSelector(controller.hasNotPermissaoEndossante);

  const onChange = (e) => {
    setEndossanteValue(e.target.value);
  };

  const onClickCancelar = useCallback(() => {
    setIsSalvarCancelar(false);
    setEndossanteValue(endossanteOld);
  }, [endossanteOld]);

  const adicionarEndossante = async () => {
    try {
      setLoadings(true);
      await atualizarEndossanteDoProcessoService(processo.id, endossanteValue);
      setIsSalvarCancelar(false);
    } finally {
      setLoadings(false);
    }
  };

  function sanitizeValue(value) {
    if (!value) {
      return '-';
    }
    return value;
  }

  React.useEffect(() => {
    setEndossanteOld(dadosProcesso.endossante);
    setEndossanteValue(dadosProcesso.endossante);
  }, [dadosProcesso.endossante]);

  return (
    <div className={styles.div__root}>
      <div className={styles.div__calculo}>
        <WrapperSubTitleForm className={styles.div__subTitleProcesso} title="Cálculos do Processo" />
        <div className={styles.div__lineInfo}>
          <b className={styles.info__name}>Valor Principal</b>
          <b className={styles.info__value}>
            {getMoneyMask(dadosProcesso.valorPrincipalProcesso, false)}
            {' '}
            (+)
          </b>
        </div>

        <div className={styles.div__lineInfo}>
          <div style={{ color: warmGrey }} className={styles.info__name}>Protesto</div>
          <div className={styles.info__value}>
            {getMoneyMask(dadosProcesso.valorProtestoProcesso, false)}
            {' '}
            (+)
          </div>
        </div>

        <div className={styles.div__lineInfo}>
          <div style={{ color: warmGrey }} className={styles.info__name}>Multa</div>
          <div className={styles.info__value}>
            {getMoneyMask(dadosProcesso.valorMultaProcesso, false)}
            {' '}
            (+)
          </div>
        </div>
        <hr className={utilStyles.divider} />

        <div className={styles.div__lineInfo}>
          <b className={styles.info__name}>SUBTOTAL</b>
          <b className={styles.info__value}>
            {getMoneyMask(dadosProcesso.subTotal, false)}
            {' '}
            (=)
          </b>
        </div>

        <div className={styles.div__lineInfo}>
          <div style={{ color: warmGrey }} className={styles.info__name}>Juros/Desp.</div>
          <div className={styles.info__value}>
            {getMoneyMask(dadosProcesso.valorJurosDespesasProcesso, false)}
            {' '}
            (+)
          </div>
        </div>

        <div className={styles.div__lineInfo}>
          <div style={{ color: warmGrey }} className={styles.info__name}>Honorários</div>
          <div className={styles.info__value}>
            {getMoneyMask(dadosProcesso.valorHonorarioProcesso, false)}
            {' '}
            (+)
          </div>
        </div>

        <div className={styles.div__lineInfo}>
          <div style={{ color: warmGrey }} className={styles.info__name}>Desconto</div>
          <div className={styles.info__value}>
            {getMoneyMask(dadosProcesso.valorDescontoProcesso, false)}
            {' '}
            (-)
          </div>
        </div>
        <hr className={utilStyles.divider} />
        <div className={styles.div__boxMain}>
          <div style={{
            width: '55%', color: blue, fontSize: '12px', fontWeight: 'bold',
          }}
          >
            Valor do Processo
          </div>
          <div
            className={styles.blueBold__title}
            style={{
              width: '45%', display: 'flex', justifyContent: 'flex-end',
            }}
          >
            {getMoneyMask(dadosProcesso.valorTotalProcesso, false)}
            {' '}
            (=)
          </div>
        </div>
        <div className={styles.div__boxMain}>
          <div style={{
            width: '55%', color: blue, fontSize: '12px', fontWeight: 'bold',
          }}
          >
            Valor da Causa
          </div>
          <div
            className={styles.blueBold__title}
            style={{
              width: '45%', display: 'flex', justifyContent: 'flex-end',
            }}
          >
            {getMoneyMask(dadosProcesso.valorCausa || 0, false)}
          </div>
        </div>
        <div className={styles.div__boxMain}>
          <div style={{
            width: '55%', color: blue, fontSize: '12px', fontWeight: 'bold',
          }}
          >
            Valor das Custas
          </div>
          <div
            className={styles.blueBold__title}
            style={{
              width: '45%', display: 'flex', justifyContent: 'flex-end',
            }}
          >
            {getMoneyMask(dadosProcesso.valorTotalCustas || 0, false)}
          </div>
        </div>
      </div>

      <div className={styles.div__resumo}>
        <WrapperSubTitleForm title="Resumo" />
        <div className={styles.div__lineInfo}>
          <b>Cliente:</b>
          <span className={styles.span__space}>
            {' '}
            {sanitizeValue(`${dadosProcesso.codigoCliente} - ${dadosProcesso.nomeCliente}`)}
          </span>
        </div>

        <div className={styles.div__lineInfo}>
          <b>Identificação Interna:</b>
          <span className={styles.span__space}>{sanitizeValue(dadosProcesso.identificacaoInterna)}</span>
        </div>

        <div className={styles.div__lineInfo}>
          <b>Devedor:</b>
          <span className={styles.span__space}>{sanitizeValue(`${dadosProcesso.codigoDevedor} - ${dadosProcesso.nomeDevedor}`)}</span>
        </div>

        <div className={styles.div__lineInfo}>
          <b>Código no Cliente:</b>
          <span className={styles.span__space}>{sanitizeValue(dadosProcesso.codigoNoCliente)}</span>
        </div>

        <div className={styles.div__lineInfo}>
          <b>Data da Abertura:</b>
          <span className={styles.span__space}>{getDateTimeMask(dadosProcesso.dataAbertura, false, false)}</span>
        </div>

        <div className={styles.div__lineInfo}>
          <b>Valor Original do Processo:</b>
          <span className={styles.span__space}>{getMoneyMask(dadosProcesso.valorTotalOriginal, false)}</span>
        </div>

        <div className={styles.div__lineInfo}>
          <b>Data da Expiração:</b>
          <span className={styles.span__space}>{getDateTimeMask(dadosProcesso.dataExpiracao, false, false)}</span>
        </div>

        <div className={styles.div__boxMain}>
          <div className={styles.blueBold__title}>
            Negociador:
          </div>
          <b
            className={styles.blackBold__title}
            style={{
              paddingLeft: '4px',
            }}
          >
            {dadosProcesso?.nomeUsuario || ''}
          </b>
        </div>

        <div className={styles.div__boxMain}>
          <div className={styles.blueBold__title} style={{ marginTop: '4px' }}>
            Fase:
          </div>
          <b
            className={styles.blackBold__title}
            style={{
              paddingLeft: '4px', marginBottom: '4px', marginTop: '4px',
            }}
          >
            {dadosProcesso?.nomeFase}
          </b>
        </div>

        <div className={styles.div__boxMain}>
          <div className={styles.blueBold__title}>
            Pasta:
          </div>
          <b
            className={styles.blackBold__title}
            style={{ paddingLeft: '4px' }}
          >
            {dadosProcesso?.nomePasta || ''}
          </b>
        </div>


        <div className={styles.div__boxMain} style={{ marginTop: '4px' }}>
          <div className={styles.blueBold__title}>
            Motivo:
          </div>
          <b
            className={styles.blackBold__title}
            style={{ paddingLeft: '4px' }}
          >
            {dadosProcesso?.motivoBaixa || ''}
          </b>
        </div>

        <div
          className={styles.blueBold__title}
          style={{ marginTop: '4px' }}
        >
          Endossante:
        </div>

        <div className={styles.div__boxMain}>
          <div className={utilStyles.w50}>
            <InputField
              fullWidth
              isForm
              disabled={naoTemPermissaoEndossante || !isSalvarCancelar}
              name="endossante"
              value={endossanteValue || ''}
              onChange={onChange}
            />
          </div>
          { !disableEditarEndossante
            && (
            <div style={{ marginTop: '-10px', marginLeft: '8px' }}>
              {isSalvarCancelar ? (
                <div>
                  <TooltipUI title="Cancelar">
                    <IconButton className={styles.cancelIcon} onClick={onClickCancelar}>
                      <CloseIcon />
                    </IconButton>
                  </TooltipUI>
                  <TooltipUI title="Salvar">
                    <IconButton className={styles.confirmarIcon} onClick={adicionarEndossante}>
                      <CheckIcon />
                    </IconButton>
                  </TooltipUI>
                </div>
              ) : (
                <TooltipUI title={!naoTemPermissaoEndossante && dadosProcesso.endossante ? 'Editar' : 'Adicionar'}>
                  <Button
                    variant="outlined"
                    className={styles.buttonEndossante}
                    disabled={naoTemPermissaoEndossante}
                    onClick={() => setIsSalvarCancelar(true)}
                  >
                    <EditIcon />
                  </Button>
                </TooltipUI>
              )}

            </div>
            )
          }
        </div>

      </div>
    </div>
  );
}

export const ResumoProcesso = React.memo(ResumoProcessoComponent);
