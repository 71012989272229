import React from 'react';
import { useDispatch } from 'react-redux';
import GerarArquivoWhatsAppProvider from '../../store/GerarArquivoWhatsApp/GerarArquivoWhatsAppProvider';
import withTokenInterceptor from '../../hoc/withTokenInterceptor/withTokenInterceptor';
import { GerarArquivoWhatsApp } from './GerarArquivoWhatsApp';
import { initializePageAction } from '../../store/theme.actions';


function GerarArquivoWhatsAppPage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Gerar Arquivo para Whatsapp'));
  return (
    <GerarArquivoWhatsAppProvider>
      <GerarArquivoWhatsApp />
    </GerarArquivoWhatsAppProvider>
  );
}

export default withTokenInterceptor(GerarArquivoWhatsAppPage);
