import { toAmericanDate } from '../../../../../../helpers';
import { gerarAcordoPasso3 } from '../../../../../../services/core/processos/processosAcordosService';

class GerarAcordoController {
  findFormaPagamentoDescription(id, formasPagamentoAcordo) {
    return formasPagamentoAcordo.filter(pagamento => pagamento.id === id)[0].value;
  }

  makePayload(idProcesso,
    dadosResumo,
    parcelas,
    dadosForm,
    tipo,
    formasPagamentoAcordo,
    idUsuarioSelecionadoSimulacao,
    justificativa,
    numero,
    ddd,
    tipoTelefone) {
    return {
      idProcesso,
      numero,
      ddd,
      tipoTelefone,
      porcentagemTaxaJuros: dadosForm.porcentagemJuros || 0.00,
      porcentagemHonorarios: dadosForm.porcentagemHonorarios || 0.00,
      porcentagemDesconto: dadosForm.porcentagemDesconto || 0.00,
      titulosJson: dadosResumo.jsonTitulos,
      erros: dadosResumo.jsonErros,
      tipoAcordo: tipo,
      qtdeParcelasUsuario: dadosForm.quantidadeParcelas,
      idUsuarioPermiteSimulacao: idUsuarioSelecionadoSimulacao,
      justificativa,
      porcentagemTaxaCartao: dadosResumo.porcentagemTaxaCartao,
      valorTaxaCartao: dadosResumo.valorTaxaCartao,
      parcelas: parcelas.map(parcela => ({
        nroParcela: parcela.nParcela,
        valor: parcela.valor,
        dataVencimento: toAmericanDate(parcela.vencimento),
        idTipoFormaPagto: parcela.formaPagamento,
        descricaoFormaPagtoAcordo: this.findFormaPagamentoDescription(parcela.formaPagamento, formasPagamentoAcordo),
        redistribuirValores: parcelas.redistribuirValores,
        chequeDetalhe: parcela?.chequeDetalhe ? (parcela.chequeDetalhe.map(detalhe => ({
          idBanco: detalhe ? detalhe.idBanco.value : 0,
          serChequeTerceiro: detalhe.serChequeTerceiro.value === true ? 'S' : 'N',
          numeroAgencia: detalhe ? detalhe.numeroAgencia.value : '',
          numeroConta: detalhe ? detalhe.numeroConta.value : '',
          dac: detalhe ? detalhe.dac.value : '',
          numeroCheque: detalhe ? detalhe.numeroCheque.value : '',
          valor: detalhe ? detalhe.valor.value : '',
        }))) : ([]),
      })),
    };
  }

  async gerarAcordoPasso3(idProcesso,
    dadosResumo,
    parcelas,
    dadosForm,
    tipo,
    formasPagamentoAcordo,
    idUsuarioSelecionadoSimulacao,
    justificativa,
    numero,
    ddd,
    tipoTelefone) {
    const payload = this.makePayload(idProcesso,
      dadosResumo,
      parcelas,
      dadosForm,
      tipo,
      formasPagamentoAcordo,
      idUsuarioSelecionadoSimulacao,
      justificativa,
      numero,
      ddd,
      tipoTelefone);
    const response = await gerarAcordoPasso3(payload);
    return response;
  }
}

export const gerarAcordoController = new GerarAcordoController();
