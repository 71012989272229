import { TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import useStyle from '../DadosJuridicosStyle';
import { TableCellRight } from '../../../../components/UI/Tabela/tableDefaultStyles';
import { getMoneyMask } from '../../../../helpers';


function TableFooterCustas({
  calculateTotalCustas,
}) {
  const styles = useStyle();

  return (
    <TableRow>
      <TableCell />
      <TableCell />
      <TableCellRight>
        <Typography className={styles.totalCustasText}>
          {getMoneyMask(calculateTotalCustas())}
        </Typography>
      </TableCellRight>
      <TableCell />
    </TableRow>
  );
}
export default TableFooterCustas;
