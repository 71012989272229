import React, { useCallback, useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import useStyle, { ButtonNovo, TableCustasHeader } from '../DadosJuridicosStyle';
import { TableDefault } from '../../../../components';
import TableHeaderCustas from './TableHeaderCustas';
import TableFooterCustas from './TableFooterCustas';
import TableBodyCustas from './TableBodyCustas';
import DialogNew from '../../../../layouts/FormDialog/DialogNew';
import DialogNovoCustas from './DialogNovoCustas';
import { findDadosJuridicos } from '../../../../services/core/processos/processoDadosJuridicosService';


function TabelaCustasComponent({
  idProcesso, updatePage, cobrancaJudicial, hasPermissoes,
}) {
  const style = useStyle();

  const [optionalDialogNovo, setOptionalDialogNovo] = useState(false);

  const [rows, setRows] = useState(null);

  const updatePageTabelaCustas = useCallback(async () => {
    updatePage();

    findDadosJuridicos(idProcesso)
      .then(
        (response) => {
          setRows(response.data.dadosJuridicosCustasQueryList);
        },
      );
  }, [idProcesso, updatePage, setRows]);

  const calculateTotalCustas = useCallback(() => {
    if (rows?.length > 0) {
      let total = 0;
      rows.forEach((element) => {
        total += element.valor;
      });
      return parseFloat(total.toFixed(2));
    }
    return 0;
  }, [rows]);

  useEffect(() => {
    if (rows === null) {
      findDadosJuridicos(idProcesso)
        .then(
          (response) => {
            setRows(response.data.dadosJuridicosCustasQueryList);
          },
        );
    }
  }, [idProcesso, setRows, rows]);

  return (
    <>
      <DialogNew
        open={optionalDialogNovo}
        text="Inclusão no Valor de Custas do Processo"
        className={style.editarCustasSize}
      >
        {optionalDialogNovo && (
        <DialogNovoCustas
          setOptionalDialogNovo={setOptionalDialogNovo}
          idProcesso={idProcesso}
          updatePage={updatePageTabelaCustas}
        />
        )}
      </DialogNew>
      <TableCustasHeader className={style.custas}>Custas</TableCustasHeader>
      <ButtonNovo
        variant="outlined"
        onClick={() => { setOptionalDialogNovo(true); }}
        disabled={!cobrancaJudicial || !hasPermissoes}
        style={{ marginLeft: '10px' }}
      >
        <FaPlus />
        <span>Novo</span>
      </ButtonNovo>
      <div>
        <TableDefault
          columnsHead={(
            <TableHeaderCustas />
            )}
          footerTableBody={(
            <TableFooterCustas
              calculateTotalCustas={calculateTotalCustas}
            />
            )}
          showTablePagination={false}
          showTableFooter
          totalElements={rows?.length}
          totalColumns={5}
        >
          {rows?.map((row, i) => (
            <TableBodyCustas
              content={row}
              key={i}
              idProcesso={idProcesso}
              cobrancaJudicial={cobrancaJudicial}
              hasPermissoes={hasPermissoes}
              updatePage={updatePageTabelaCustas}
            />
          ))}
        </TableDefault>
      </div>
    </>
  );
}

export const TabelaCustas = TabelaCustasComponent;
