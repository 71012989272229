import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';

function TableHeaderCustas() {
  return (
    <TableRow>
      <TableCell height={38} width={94}>
        Data
      </TableCell>
      <TableCell height={38} width={594}>
        Histórico
      </TableCell>
      <TableCell height={38} width={219}>
        Valor
      </TableCell>
      <TableCell height={38} width={139}>
        Ações
      </TableCell>
    </TableRow>
  );
}
export default TableHeaderCustas;
