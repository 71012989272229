import { toAmericanDate } from '../../../helpers';
import { CORE, gateway } from '../../api';

export function findDadosJuridicos(idProcesso) {
  return gateway.get(`${CORE}/processos/dados-juridicos/${idProcesso}`);
}

export function salvarInformacoesDadosJuridicos(valorCausa,
  detalhe,
  estarComAdvogadoExterno,
  idProcesso) {
  const body = {
    valorCausa,
    detalhesValorCausa: detalhe,
    estarLancadoValorCausa: false,
    estarComAdvogadoExterno,
  };
  return gateway.post(`${CORE}/processos/dados-juridicos/salvar-informacoes/${idProcesso}`, body);
}

export function salvarCustasDadosJuridicos(data,
  historico,
  valor,
  idProcesso) {
  const body = {
    dataLancamento: toAmericanDate(data),
    descricao: historico,
    valor,
  };
  return gateway.post(`${CORE}/processos/dados-juridicos/salvar-custas/${idProcesso}`, body);
}

export function excluirCustasDadosJuridicos(idProcessoCustas,
  data,
  historico,
  valor,
  idProcesso) {
  const body = {
    dataLancamento: toAmericanDate(data),
    descricao: historico,
    valor,
  };
  return gateway.post(`${CORE}/processos/dados-juridicos/excluir-custas/${idProcesso}/${idProcessoCustas}`, body);
}

export function editarCustasDadosJuridicos(data,
  historico,
  valor,
  idProcessoCustas,
  idProcesso) {
  const body = {
    dataLancamento: toAmericanDate(data),
    descricao: historico,
    valor,
  };

  return gateway.post(`${CORE}/processos/dados-juridicos/atualizar-custas/${idProcesso}/${idProcessoCustas}`, body);
}

export function lancarCausaDadosJuridicos(serFinal,
  valorCausa,
  detalhe,
  estarComAdvogadoExterno,
  idProcesso) {
  const body = {
    valorCausa,
    detalhesValorCausa: detalhe,
    estarComAdvogadoExterno,
  };

  return gateway.post(`${CORE}/processos/dados-juridicos/lancar-causa/${idProcesso}/${serFinal}`, body);
}

export function lancarCustaDadosJuridicos(idProcessoCustas) {
  return gateway.post(`${CORE}/processos/dados-juridicos/lancar-custa/${idProcessoCustas}`);
}
