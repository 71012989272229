import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import Grid24 from '../../../../components/UI/Grid24';
import DateField from '../../../../components/UI/Field/Date/DateField';
import { getRequiredLabel, toBrazilianDate } from '../../../../helpers';
import { InputField } from '../../../../components';
import MoneyField from '../../../../components/UI/Field/Money/MoneyField';
import { editarCustasDadosJuridicos } from '../../../../services/core/processos/processoDadosJuridicosService';


function DialogEditarCustas({
  setOptionalDialogEditar, content, updatePage, idProcesso,
}) {
  const [data, setData] = useState('');
  const [historico, setHistorico] = useState('');
  const [valor, setValor] = useState('');

  const disableConfirmarCustas = !data || !historico || !valor;

  const onClickEditarCustas = useCallback(async () => {
    try {
      await editarCustasDadosJuridicos(data,
        historico,
        valor,
        content.idProcessoCustas,
        idProcesso);
      setOptionalDialogEditar(false);
      updatePage();
      toast.success('Lançamento alterado com sucesso.');
    } catch {
      setOptionalDialogEditar(false);
      toast.error('Não foi possível alterar o lançamento. Tente novamente.', { style: { width: '392px' } });
    }
  }, [content, data, historico, setOptionalDialogEditar, updatePage, valor, idProcesso]);

  useEffect(() => {
    setData(toBrazilianDate(content.dataLancamento));
    setHistorico(content.descricao);
    setValor(content.valor);
  }, [content, setData, setHistorico, setValor]);

  return (
    <>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} style={{ marginBottom: '15px' }}>
          <Grid24 item xs={24} sm={8}>
            <DateField
              isForm
              fullWidth
              label={getRequiredLabel('Data', true)}
              name="data"
              value={data}
              onChange={(e) => { setData(e.target.value); }}
            />
          </Grid24>
          <Grid24
            item
            xs={24}
            sm={8}
            style={{
              paddingRight: '8px',
            }}
          >
            <InputField
              isForm
              fullWidth
              autoComplete="off"
              label={getRequiredLabel('Histórico', true)}
              name="historico"
              value={historico}
              onChange={(e) => { setHistorico(e.target.value); }}
            />
          </Grid24>
          <Grid24 item xs={24} sm={8}>
            <MoneyField
              isForm
              fullWidth
              acceptEmptyValue
              label={getRequiredLabel('Valor', true)}
              name="valor"
              value={valor}
              onChange={(e) => { setValor(e.target.value); }}
            />
          </Grid24>
        </Grid24>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          onClick={() => { setOptionalDialogEditar(false); }}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickEditarCustas}
          disabled={disableConfirmarCustas}
        >
          Confirmar
        </Button>
      </div>

    </>
  );
}


export default DialogEditarCustas;
