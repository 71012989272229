import React from 'react';
import { Tooltip } from '@material-ui/core';
import { IoMdEye } from 'react-icons/io';
import { RiFileSearchFill } from 'react-icons/ri';
import TableHeader from '../../../../../../models/TableHeader';
import TooltipUI from '../../../../../../components/UI/Tooltip/Tooltip';
import { CancelarEnvioIcon } from '../../../../../../assets/Icons/CancelarEnvioIcon';
import IconAgrupamentoBlue from '../../../../../../assets/images/icons/iconAgrupamentoBlue.svg';
import IconAgrupamento from '../../../../../../assets/images/icons/iconAgrupamento.svg';
import { blackTwo, lightGrey } from '../../../../../../helpers/constants/colors.constants';
import { TableActionButton, TableActionContainer } from '../../../../../../components/UI/Tabela/tableDefaultStyles';

class NotasFiscaisFactory {
  makeCabecalhoTabela() {
    return [
      new TableHeader({
        id: 'numeroNota',
        label: 'Nº da RPS',
        alignCell: 'center',
        maxWidth: 95,
      }),
      new TableHeader({
        id: 'numeroNotaFiscalEletronica',
        label: 'Nº NFe',
        alignCell: 'center',
        maxWidth: 80,
      }),
      new TableHeader({
        id: 'dataNota',
        label: 'Data da Emissão',
        alignCell: 'center',
        maxWidth: 118,
      }),
      new TableHeader({
        id: 'idsPrestacoesAgrupadas',
        label: <img src={IconAgrupamentoBlue} alt="" width="15px" height="14px" />,
        alignCell: 'center',
        maxWidth: 45,
      }),
      new TableHeader({
        id: 'tipoEmissao',
        label: 'Tipo Emissão',
        alignCell: 'center',
        maxWidth: 108,
      }),
      new TableHeader({
        id: 'valorAmigavel',
        label: 'Honorários Amigáveis',
        alignCell: 'center',
        maxWidth: 140,
      }),
      new TableHeader({
        id: 'valorJudicial',
        label: 'Honorários Judiciais',
        alignCell: 'center',
        maxWidth: 140,
      }),
      new TableHeader({
        id: 'valorDespesa',
        label: 'Despesas',
        alignCell: 'center',
        maxWidth: 115,
      }),
      new TableHeader({
        id: 'statusNotaFiscal',
        label: 'Status',
        alignCell: 'center',
        maxWidth: 135,
      }),
      new TableHeader({
        id: 'acoes',
        label: 'Ações',
        alignCell: 'center',
        maxWidth: 75,
      }),
    ];
  }

  makeConteudoTabela(notasFiscais, permissoes, handleCancelNotaFiscais, visualizarNotaFiscalCliente, downloadProtocoloCancelamentoNF) {
    if (!notasFiscais || notasFiscais.length === 0) {
      return [];
    }

    return notasFiscais.map(notas => ({
      id: notas.idNotaFiscalCliente,
      values: {
        numeroNota: notas.numeroNota,
        numeroNotaFiscalEletronica: notas.numeroNotaFiscalEletronica,
        dataNota: notas.dataNota,
        idsPrestacoesAgrupadas: notas.idsPrestacoesAgrupadas !== '' ? (
          <TooltipUI title={notas.idsPrestacoesAgrupadas}>
            <img src={IconAgrupamento} alt="" width="15px" height="14px" />
          </TooltipUI>
        )
          : '',
        tipoEmissao: notas.tipoEmissao,
        valorAmigavel: notas.valorAmigavel,
        valorJudicial: notas.valorJudicial,
        valorDespesa: notas.valorDespesa,
        statusNotaFiscal: notas.statusNotaFiscal,
        acoes: (
          <div>
            <TableActionContainer>
              <span style={{ marginRight: '4px' }}>
                {notas.urlPrefeituraNF === null ? (
                  <TableActionButton
                    disabled
                  >
                    <IoMdEye
                      size={20}
                      color={lightGrey}
                    />
                  </TableActionButton>
                ) : (
                  <Tooltip title="Visualizar" placement="bottom">
                    <TableActionButton
                      onClick={() => visualizarNotaFiscalCliente(notas.urlPrefeituraNF)}
                    >
                      <IoMdEye
                        size={20}
                        color={blackTwo}
                      />
                    </TableActionButton>
                  </Tooltip>
                )}
              </span>
              {(!permissoes.prestarContas
                || notas.statusNotaFiscal === 'Cancelada'
                || notas.statusNotaFiscal === 'Cancelada fora do prazo') ? (
                  <TableActionButton
                    disabled
                  >
                    <CancelarEnvioIcon
                      size={14}
                      color={lightGrey}
                    />
                  </TableActionButton>
                ) : (
                  <Tooltip title="Cancelar" placement="bottom">
                    <TableActionButton
                      onClick={() => handleCancelNotaFiscais(notas.idNotaFiscalCliente)}
                    >
                      <CancelarEnvioIcon
                        size={14}
                        color={blackTwo}
                      />
                    </TableActionButton>
                  </Tooltip>
                )}
              {(notas.statusNotaFiscal === 'Ativa' || ((notas.statusNotaFiscal === 'Cancelada'
                || notas.statusNotaFiscal === 'Cancelada fora do prazo')
                && (notas.numeroNotaFiscalEletronica === null || notas.codigoVerificacao === null))) ? (
                  <TableActionButton
                    disabled
                  >
                    <RiFileSearchFill
                      size={20}
                      color={lightGrey}
                    />
                  </TableActionButton>
                ) : (
                  <Tooltip title="Visualizar Protocolo de Cancelamento" placement="bottom">
                    <TableActionButton
                      onClick={() => downloadProtocoloCancelamentoNF(notas.idNotaFiscalCliente)}
                    >
                      <RiFileSearchFill
                        size={20}
                        color={blackTwo}
                      />
                    </TableActionButton>
                  </Tooltip>
                )}
            </TableActionContainer>
          </div>
        ),
        codigoVerificacao: notas.codigoVerificacao,
        nomeUsuarioEmissao: notas.nomeUsuarioEmissao,
        nomePrefeitura: notas.nomePrefeitura,
        valorLiquido: notas.valorLiquido,
        valorRetencaoPIS: notas.valorRetencaoPIS,
        valorRetencaoCOFINS: notas.valorRetencaoCOFINS,
        valorRetencaoCSLL: notas.valorRetencaoCSLL,
        valorRetencaoIR: notas.valorRetencaoIR,
        autorizarEmissaoNF: notas.autorizarEmissaoNF,
        idNotaFiscalCliente: notas.idNotaFiscalCliente,
      },
    }));
  }
}

export const notasFiscaisFactory = new NotasFiscaisFactory();
