import React from 'react';
import { useSelector } from 'react-redux';
import useStyle from './DadosJuridicosStyle';
import LoadingUI from '../../../components/UI/Loading/LoadingUI';
import { Informacoes } from './Informacoes/Informacoes';
import { TabelaCustas } from './TabelaCustas/TabelaCustas';

function DadosJuridicosComponent({ idProcesso, updatePage, cobrancaJudicial }) {
  const style = useStyle();
  const permissoesUsuario = useSelector(state => state.authentication.userInformation.permissoes);
  const hasPermissoes = permissoesUsuario.includes('ROLE_1211');
  return (
    <div className={style.container}>
      <LoadingUI />
      <Informacoes
        idProcesso={idProcesso}
        updatePage={updatePage}
        cobrancaJudicial={cobrancaJudicial}
        hasPermissoes={hasPermissoes}
      />
      <TabelaCustas
        idProcesso={idProcesso}
        updatePage={updatePage}
        cobrancaJudicial={cobrancaJudicial}
        hasPermissoes={hasPermissoes}
      />
    </div>
  );
}

export const DadosJuridicos = DadosJuridicosComponent;
