import { TableRow } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { FaCashRegister, FaPen, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import {
  TableActionButton, TableCellCenter, TableCellLeft, TableCellRight,
} from '../../../../components/UI/Tabela/tableDefaultStyles';
import { getMoneyMask, toBrazilianDate } from '../../../../helpers';
import TooltipUI from '../../../../components/UI/Tooltip/Tooltip';
import OptionDialogNew from '../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';
import { excluirCustasDadosJuridicos, lancarCustaDadosJuridicos } from '../../../../services/core/processos/processoDadosJuridicosService';
import DialogNew from '../../../../layouts/FormDialog/DialogNew';
import DialogEditarCustas from './DialogEditarCustas';
import useStyle from '../DadosJuridicosStyle';

function TableBodyCustas({
  content,
  idProcesso,
  updatePage,
  cobrancaJudicial,
  hasPermissoes,
}) {
  const style = useStyle();

  const [optionalDialogExcluir, setOptionalDialogExcluir] = useState(false);
  const [optionalDialogEditar, setOptionalDialogEditar] = useState(false);
  const [optionalDialogLancarValor, setOptionalDialogLancarValor] = useState(false);

  const onClickButtonExcluir = useCallback(async (idProcessoCustas) => {
    try {
      await excluirCustasDadosJuridicos(idProcessoCustas,
        content.dataLancamento,
        content.descricao,
        content.valor,
        idProcesso);
      setOptionalDialogExcluir(false);
      updatePage();
      toast.success('Lançamento excluído com sucesso.');
    } catch {
      setOptionalDialogExcluir(false);
      toast.error('Não foi possível excluir o lançamento. Tente novamente.', { style: { width: '392px' } });
    }
  }, [updatePage, content, idProcesso]);

  const onClickButtonLancarValor = useCallback(async (idProcessoCustas) => {
    try {
      await lancarCustaDadosJuridicos(idProcessoCustas).then((response) => {
        if (response.data.codResult === 0) {
          toast.success('Valor enviado com sucesso.');
        } else {
          toast.warn(response.data.msgResult);
        }
      });
    } catch {
      toast.error('Não foi possível enviar o valor dos dados jurídicos. Tente novamente.', { style: { width: '392px' } });
    } finally {
      setOptionalDialogLancarValor(false);
      updatePage();
    }
  }, [updatePage]);

  return (
    <>
      <OptionDialogNew
        open={optionalDialogLancarValor}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={() => { setOptionalDialogLancarValor(false); }}
        onClickConfirm={() => { onClickButtonLancarValor(content.idProcessoCustas); }}
        text="Confirma o envio do valor das Custas na Conta Corrente do Cliente?"
      />
      <DialogNew
        open={optionalDialogEditar}
        text="Alteração no Valor de Custas do Processo"
        className={style.editarCustasSize}
      >
        {optionalDialogEditar && (
        <DialogEditarCustas
          setOptionalDialogEditar={setOptionalDialogEditar}
          content={content}
          updatePage={updatePage}
          idProcesso={idProcesso}
        />
        )}
      </DialogNew>
      <OptionDialogNew
        open={optionalDialogExcluir}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={() => { setOptionalDialogExcluir(false); }}
        onClickConfirm={() => { onClickButtonExcluir(content.idProcessoCustas); }}
        text="Deseja excluir as Custas selecionada?"
      />
      <TableRow>
        <TableCellCenter>
          {toBrazilianDate(content.dataLancamento)}
        </TableCellCenter>
        <TableCellLeft>
          {content.descricao}
        </TableCellLeft>
        <TableCellRight>
          {getMoneyMask(content.valor)}
        </TableCellRight>
        <TableCellCenter>
          <TooltipUI title="Lançar valor">
            <TableActionButton
              disabled={content.estarLancado || !cobrancaJudicial || !hasPermissoes}
              style={{ marginRight: '10px' }}
              onClick={() => { setOptionalDialogLancarValor(true); }}
            >
              <FaCashRegister size={19} />
            </TableActionButton>
          </TooltipUI>
          <TooltipUI title="Editar">
            <TableActionButton
              disabled={content.estarLancado || !cobrancaJudicial || !hasPermissoes}
              style={{ marginRight: '10px' }}
              onClick={() => { setOptionalDialogEditar(true); }}
            >
              <FaPen size={19} />
            </TableActionButton>
          </TooltipUI>
          <TooltipUI title="Excluir">
            <TableActionButton
              disabled={content.estarLancado || !cobrancaJudicial || !hasPermissoes}
              style={{ marginRight: '10px' }}
              onClick={() => { setOptionalDialogExcluir(true); }}
            >
              <FaTrash size={19} />
            </TableActionButton>
          </TooltipUI>
        </TableCellCenter>
      </TableRow>
    </>
  );
}

export default TableBodyCustas;
