
import React from 'react';
import { IconButton } from '@material-ui/core';
import { FaTrash } from 'react-icons/fa';
import TableHeader from '../../models/TableHeader';
import TooltipUI from '../../components/UI/Tooltip/Tooltip';
import { blackTwo } from '../../helpers/constants/colors.constants';

class EmailEntradaBorderoFactory {
  makeCabecalhoTabela() {
    return [
      new TableHeader({
        id: 'idProcesso',
        label: 'Processo',
        alignCell: 'center',
        maxWidth: 125,
      }),
      new TableHeader({
        id: 'nomeCliente',
        label: 'Cliente',
        alignCell: 'left',
        maxWidth: 125,
      }),
      new TableHeader({
        id: 'nomeDevedor',
        label: 'Devedor',
        alignCell: 'left',
        maxWidth: 125,
      }),
      new TableHeader({
        id: 'email',
        label: 'Email',
        alignCell: 'center',
        maxWidth: 125,
      }),
      new TableHeader({
        id: 'telefone',
        label: 'Telefone',
        alignCell: 'center',
        maxWidth: 125,
      }),
      new TableHeader({
        id: 'notificacao',
        label: 'Notificação',
        alignCell: 'left',
        maxWidth: 125,
      }),
      new TableHeader({
        id: 'nomeFase',
        label: 'Fase',
        alignCell: 'left',
        maxWidth: 125,
      }),
      new TableHeader({
        id: 'acoes',
        label: 'Ações',
        alignCell: 'center',
        maxWidth: 125,
      }),
    ];
  }

  makeConteudoTabela(emailsEntradaBordero, handlerExcluirEmailEntradaBordero) {
    if (!emailsEntradaBordero || emailsEntradaBordero.length === 0) {
      return [];
    }

    return emailsEntradaBordero.map(emailEntradaBordero => ({
      id: emailEntradaBordero.idEmailEntradaBordero,
      values: {
        idProcesso: emailEntradaBordero.idProcesso,
        nomeCliente: emailEntradaBordero.nomeCliente,
        nomeDevedor: emailEntradaBordero.nomeDevedor,
        email: emailEntradaBordero.email,
        telefone: emailEntradaBordero.telefone,
        notificacao: emailEntradaBordero.notificacao,
        nomeFase: emailEntradaBordero.nomeFase,
        acoes: (
          <div>
            <TooltipUI title="Excluir">
              <IconButton
                size="small"
                style={{ color: blackTwo }}
                onClick={() => handlerExcluirEmailEntradaBordero(emailEntradaBordero.idEmailEntradaBordero)}
              >
                <FaTrash size={20} />
              </IconButton>
            </TooltipUI>
          </div>
        ),
      },
    }));
  }
}

export const emailEntradaBorderoFactory = new EmailEntradaBorderoFactory();
