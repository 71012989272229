import { Box, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import WrapperSubTitleForm from '../../../components/UI/WrapperSubTitleForm';
import useStyles from './acaoReenviarWhatsappStyle';
import { ButtonUI } from '../../../components';
import Grid24 from '../../../components/UI/Grid24';
import RadioField from '../../../components/UI/Field/Radio/RadioField';
import useUtilStyles from '../../../helpers/styles/utils.styles';

import { GroupDefinitions } from '../../../components/Cliente/Crud/Tabs/BaseCalculo/Honorarios/styles';
import { salvarReenvioWhatsapp } from '../../../services/core/processos/processosService';

function AcaoReenviarWhatsapp({
  idFaseAtual, idProcesso, podeReenviarWhatsapp, updatePage,
}) {
  const styles = useStyles();
  const utilStyles = useUtilStyles();
  const existePosProducao = podeReenviarWhatsapp?.includes('POS PROCESSAMENTO');
  const existePorFase = podeReenviarWhatsapp?.includes('POR FASE');

  const statusContatoDefinitionInicial = useCallback(() => {
    if (existePorFase && existePosProducao) {
      return '0';
    }
    if (existePorFase) {
      return '1';
    }
    if (existePosProducao) {
      return '0';
    }
    return '0';
  }, [existePosProducao, existePorFase]);

  const [statusContatoDefinition, setStatusContatoDefinition] = useState(statusContatoDefinitionInicial);

  const onChangeStatusContatoDefinitionHandler = useCallback((e) => {
    const { value } = e.target;
    setStatusContatoDefinition(value);
  }, []);

  const submitHandler = useCallback(async () => {
    try {
      await salvarReenvioWhatsapp(
        idProcesso,
        idFaseAtual,
        statusContatoDefinition,
      );
      updatePage();
      toast.success('Ação realizada com sucesso!');
    } catch (err) {
      toast.error('Erro ao Salvar o Reenvio de Whatsapp.');
    }
  }, [idProcesso, idFaseAtual, statusContatoDefinition, updatePage]);


  return (
    <Box>
      <WrapperSubTitleForm className={styles.titleReenviarWhatsapp}>
        Reenviar Whatsapp
      </WrapperSubTitleForm>
      <Box>
        <form className={styles.formReenviarWhatsapp}>
          <Grid24 container>
            <Grid24 item xs={24} className={utilStyles.mt9}>
              <Typography component="P" className={styles.infos__gerais}>
                <p>Escolha o Tipo do Contato:</p>
              </Typography>
            </Grid24>
            <Grid24 item xs={24} direction="column" className={utilStyles.mt5}>
              <GroupDefinitions
                aria-label="status-contato"
                value={statusContatoDefinition}
                onChange={onChangeStatusContatoDefinitionHandler}
              >
                <RadioField label="Pós Processamento do Borderô" value="0" disabled={!existePosProducao} />
                <RadioField label="Processos em uma fase específica" value="1" disabled={!existePorFase} />
              </GroupDefinitions>

            </Grid24>
          </Grid24>
        </form>
      </Box>
      <Box className={styles.footerReenviarWhatsapp}>
        <ButtonUI
          color="success"
          style={{ width: '136px' }}
          onClick={submitHandler}
        >
          Concluir
        </ButtonUI>
      </Box>
    </Box>
  );
}


export default AcaoReenviarWhatsapp;
