import styled from 'styled-components';
import { Button, makeStyles } from '@material-ui/core';
import { white } from '../../../helpers/constants/colors.constants';

export default makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  descricao: {
    display: 'flex',
    flexDirection: 'row',
  },
  custas: {
    marginTop: '22px',
  },
  totalCustasText: {
    fontWeight: '700',
    fontSize: '14px',
    color: '#034DDC',
  },
  editarCustasSize: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '585px',
      maxHeigh: '359px',
    },
  },


});

export const HeaderTitulos = styled.div`
  margin: 0;
  display: flex;
  justify-content: flex-end;
  button {
    color: #564872;
    border: 1px solid #564872;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    width: 35px;
    height: 26px;
    text-transform: none;
    min-width: 35px;
  }
`;

export const ButtonEditar = styled(Button)`
margin-left: 9px;
font-size: 14px;
color:  #564872;

:disabled {
color: #c4c4c4;
}

& .MuiButton-label span {
    margin-left: 8px;
  }
`;

export const ButtonNovo = styled(Button)`
  font-size: 14px;
  line-height: 16px;
  padding: 6px 8px;
  font-weight: bold;
  color: #564872;
  border: 2px solid #564872;
  text-transform: none;
  width: 90px;
  margin-top: auto;
  margin-bottom: 10px;
  height: fit-content;
  align-self: end;

  >span span {
    display: inline-block;
    margin-left: 6px;
  }
`;

export const ButtonLancar = styled(Button)`
  font-size: 14px;
  line-height: 16px;
  padding: 6px 8px;
  font-weight: bold;
  color: #564872;
  border: 2px solid #564872;
  text-transform: none;
  width: 390px;
  margin-top: auto;
  height: fit-content;

  >span span {
    display: inline-block;
    margin-left: 6px;
  }
`;

export const TableCustasHeader = styled.div`
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
`;

export const Container = styled.div`
  margin: 6px 0px 12px 0;
  box-Shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-Color: ${white};
  padding:22px;

  > div+div {
    display:flex ;
    flex-direction:column ;
      }

 // #resumo{
 //   display:flex ;
   // flex-direction:column ;
   // justify-content:start ;
   // width:45% ;
  //}
`;

export const ContainerResumo = styled.div`
  display: flex ;
  justify-content:center ;
  gap:8px ;
  flex-flow:column ;


    > h3 {
      display:flex ;
      justify-content:center ;
      font-size:16px ;
    }

    > div {
      display:flex ;
      flex-direction:row ;
      gap:15px ;
      justify-content:center ;
      color:${white} ;

    h3 {
      text-align:center ;
      font-size:16px ;
    }

    h4 {
      text-align:center ;
      font-size:14px ;
      font-weight:700 ;
    }
    }

    > div + div {
      display:flex ;
      flex-direction:row ;
      gap:15px ;
      justify-content:center ;
      color:${white} ;
    }
`;

export const ButtonImprimir = styled(Button)`
display: flex;
justify-content: space-between;
margin-top:30px ;

&.MuiButton-root {
  width: 73px;
  padding: 4px 0;
  font-weight: bold;
  text-transform: none;
  color: #000000;
  font-size:12px ;

  &.Mui-disabled {
    opacity: 0.64;
  }
}
`;
