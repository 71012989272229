function buildInitialStates() {
  return {
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    tableView: [],
    common: {
      loadingPage: false,
    },
  };
}

export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    default:
      return states;
  }
};
