import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { white } from '../../helpers/constants/colors.constants';

export default makeStyles({
  titleTextoPadrao: {
    marginLeft: '5px',
    marginTop: '13px',
  },
  mt9ml40: {
    marginTop: '9px',
    marginLeft: '40px',
  },
  div__buttons: {
    width: '166px',
    height: '35px',
    marginRight: '22px',
  },
  div__align: {
    display: 'flex',
    marginTop: '22px',
    marginBottom: '22px',
    flexDirection: 'row-reverse',
    alignItems: 'end',
  },
  infos__gerais: {
    marginBottom: '16px',
    fontSize: '12px',
  },
});

export const Container = styled.div`
  margin: 6px 0px 12px 0;
  box-Shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-Color: ${white};
  padding:22px;
  border-radius: 7px;

  > div+div {
    display:flex ;
    flex-direction:column ;
      }

  #resumo{
    display:flex ;
    flex-direction:column ;
    justify-content:start ;
    width:45% ;
  }
`;
