import React, { memo, useMemo } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TablePagination } from '@material-ui/core';
import { TableWithAccordion } from './EmailEntradaBorderoStyles';
import PaginationActions from '../../components/UI/Tabela/PaginadaSimples/Pagination/PaginationActions';
import { useUtilStyles } from '../../helpers';
import TooltipUI from '../../components/UI/Tooltip/Tooltip';

function Row({
  row, columns, styles,
}) {
  const getContent = (value, column) => {
    if (column.checkbox) {
      return (
        value
      );
    }

    if (column.showTip) {
      return (
        <TooltipUI title={value} placement="bottom" interactive>
          <pre
            className={styles.overFlowText}
            style={{ maxWidth: column.maxWidth - 16 }}
          >
            {column.format && typeof value === 'number' ? column.format(value) : value}
          </pre>
        </TooltipUI>
      );
    }
    if (column.hiddenFullLine) {
      return (
        <pre
          className={styles.overFlowText}
          style={{ maxWidth: column.maxWidth - 16 }}
        >
          {column.format && typeof value === 'number' ? column.format(value) : value}
        </pre>
      );
    }
    return <pre className={styles.overFlowText}>{column.format && typeof value === 'number' ? column.format(value) : value}</pre>;
  };

  return (
    <>
      <TableRow className={styles.tableRowRoot}>
        {columns.map((column) => {
          const value = row.values[column.id];

          return (
            <TableCell
              key={column.id}
              style={{
                maxWidth: column.maxWidth,
                minWidth: column.minWidth,
                width: column.width,
                borderTop: '1px solid #e0e0e0',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {getContent(value, column)}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
}

const layoutConstants = {
  LABEL_ROWS_PER_PAGE: 'Linhas por página',
  PAGINA_ANTERIOR: 'Página Anterior',
  PROXIMA_PAGINA: 'Próxima Página',
};

function TabelaEmailEntradaBordero({
  columns = [],
  rows = [],
  size = 0,
  rowsPerPageOptions = [15, 20, 25],
  page = 0,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
}) {
  const styles = useUtilStyles();

  const columnLength = useMemo(() => columns.length + 1, [columns.length]);

  const buildEmptyRows = () => {
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

    const content = [];
    for (let i = 0; i < emptyRows; i += 1) {
      content.push(
        <TableRow key={i} style={{ height: 31 }}>
          <TableCell
            style={{
              borderTop: '1px solid #e0e0e0',
            }}
            colSpan={columnLength}
          >
            <p />
          </TableCell>
        </TableRow>,
      );
    }
    return content;
  };

  return (
    <>
      <TableWithAccordion>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  id={column.id}
                  key={column.id}
                  style={{
                    maxWidth: column.maxWidth,
                    minWidth: column.minWidth,
                    width: column.width,
                    textAlign: column.align,
                    borderTop: '1px solid #e0e0e0',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <Row
                key={row.id}
                row={row}
                columns={columns}
                styles={styles}
                columnLength={columnLength}
              />
            ))}
            {buildEmptyRows()}
          </TableBody>
        </Table>
      </TableWithAccordion>
      <TablePagination
        style={{ width: '100%' }}
        rowsPerPageOptions={rowsPerPageOptions}
        colSpan={columns.length}
        component="div"
        count={size}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={layoutConstants.LABEL_ROWS_PER_PAGE}
        backIconButtonProps={{
          'aria-label': layoutConstants.PAGINA_ANTERIOR,
        }}
        nextIconButtonProps={{
          'aria-label': layoutConstants.PROXIMA_PAGINA,
        }}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        ActionsComponent={PaginationActions}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />
    </>
  );
}

export default memo(TabelaEmailEntradaBordero);
