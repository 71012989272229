import React, { useState, useCallback, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, TextField } from '@mui/material';
import { ContainerNovoAcordo, StepSubLabel } from '../../AcordosStyle';
import {
  useUtilStyles, getMoneyMask, DateUtils, getRequiredLabel,
} from '../../../../../helpers';
import {
  ButtonUI, SelectorField, TableDefault,
} from '../../../../../components';
import TableHeaderPassoTres from './TableHeaderPassoTres';
import TableBodyPassoTres from './TableBodyPassoTres';
import usePasso3Styles, {
  Passo3Body, Passo3Footer, Passo3FooterActions,
} from './Passo3Styles';
import DialogNovaAlterarParcela from './NovaAlterarParcela/DialogNovaAlterarParcela';
import { findTiposFormaPagamentoAction, findTiposTelefoneAction, findUsuariosPermitemSimulacaoAction } from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { gerarAcordoController } from './GerarAcordo/gerarAcordoController';
import Grid24 from '../../../../../components/UI/Grid24';
import TableFooterPassoTres from './TableFooterPassoTres';
import { ITEMS_ANALISE_ACORDO } from '../PassoDois/PassoDoisConstants';
import DialogNew from '../../../../../layouts/FormDialog/DialogNew';
import { loadingInspecaoProcessoController } from '../../../loadingInspecaoProcessoController';
import MaskField from '../../../../../components/UI/Field/Mask';
import { CELPHONE_MASK, RESIDENCIAL_MASK, SERVICO_MASK } from '../../../../../helpers/constants/layout.constants';

function NovoAcordoPassoTres({
  idProcesso,
  setOpenButtonNovoAcordo,
  setLoadings,
  handleBackStep,
  dadosResumo,
  parcelas,
  setParcelas,
  dadosPadraoForm,
  pesquisarAcordos,
  exibirApenasAcordosAtivosEhPropostas,
  settingsTable,
  columnOrdenacao,
  updatePage,
  getDadosResumo,
  setInfoAdicionaisAcordo,
  dadosFormFields,
  setDadosDefault,
  dadosDefault,
  parametrosInternosAbe,
  dataFinalPasso3,
}) {
  const utilStyles = useUtilStyles();
  const passo3Styles = usePasso3Styles();
  const dispatch = useDispatch();

  const tiposTelefone = useSelector(states => states.selectors.tiposTelefone.selector);

  const [telefone, setTelefone] = useState({ numero: '', ddd: '', tipoTelefone: 'CELULAR' });

  const [openDialogErroSomatoriaAoGerarAcordo, setOpenDialogErroSomatoriaAoGerarAcordo] = useState(false);
  const [msgErroAoGerarSimulacaoAcordo, setMsgErroAoGerarSimulacaoAcordo] = useState('');

  const formasPagamentoAcordo = useSelector(states => states.selectors.tiposFormaPagamento.selector);

  // Nova Parcela, Alterar Parcela
  const [openDialogNovaAlterarParcela, setopenDialogNovaAlterarParcela] = useState(false);
  const [clickedParcelaOnEdit, setClickedParcelaOnEdit] = useState({});

  // Vencimento Ordenação
  const [columnOrdenacaoVencimento, setColumnOrdenacaoVencimento] = useState(
    { element: 'vencimentoParcela', order: 'ASC' },
  );
  const [arrowDirectionVencimento, setArrowDirectionVencimento] = useState(false);

  // Selector usuário aprovar simulação
  const usuariosPermitemProposta = useSelector(states => states.selectors.usuariosPermitemSimulacao.selector);
  const [idUsuarioSelecionadoPermiteProposta, setIdUsuarioSelecionadoPermiteProposta] = useState(0);
  const [justificativa, setTextoJutificativa] = useState('');

  // Métodos
  const findValuePagamento = id => formasPagamentoAcordo.filter(pagamento => pagamento.id === id);

  const handleClickButtonAlterarParcela = (parcelaAlterada) => {
    setopenDialogNovaAlterarParcela(true);
    const newParcela = {
      ...parcelaAlterada,
      formaPagamento: parcelaAlterada?.formaPagamento,
    };
    setClickedParcelaOnEdit(newParcela);
  };

  const reOrderParcelas = (parcelasArr, arrowDirection) => {
    if (parcelasArr.length > 1) {
      if (arrowDirection) {
        // Datas maiores primeiro
        parcelasArr.sort((a, b) => DateUtils.getISODateFromText(b.vencimento) - DateUtils.getISODateFromText(a.vencimento));
      } else {
        // Datas menores primeiro
        parcelasArr.sort((a, b) => DateUtils.getISODateFromText(a.vencimento) - DateUtils.getISODateFromText(b.vencimento));
      }
    }
    return parcelasArr;
  };

  function reNumberParcelas(parcelasArr, arrowDirection) {
    if (!arrowDirection) {
      // Datas menores primeiro
      const newParcelas = parcelasArr.map((parcela, idx) => ({ ...parcela, nParcela: idx + 1 }));
      return newParcelas;
    }
    // Datas maiores primeiro
    const newParcelas = parcelasArr.map((parcela, idx, arr) => ({ ...parcela, nParcela: arr.length - idx }));
    return newParcelas;
  }

  const calculateTotalParcelas = useCallback(() => {
    if (parcelas?.length > 0) {
      let total = 0;
      parcelas.forEach((element) => {
        total += element.valor;
      });
      return parseFloat(total.toFixed(2));
    }
    return 0;
  }, [parcelas]);

  const onClickColumnVencimento = useCallback(() => {
    setArrowDirectionVencimento((arrowDirection) => {
      const reOrderedParcelas = reOrderParcelas(parcelas, !arrowDirection);
      setParcelas(reOrderedParcelas);
      return !arrowDirection;
    });
  }, [parcelas, setParcelas]);

  async function onClickSubmitGerarSimulacaoAcordo(tipo) {
    try {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
      await gerarAcordoController
        .gerarAcordoPasso3(idProcesso,
          dadosResumo,
          parcelas,
          dadosPadraoForm,
          tipo,
          formasPagamentoAcordo,
          idUsuarioSelecionadoPermiteProposta,
          justificativa,
          telefone.numero,
          telefone.ddd,
          telefone.tipoTelefone)
        .then((value) => {
          switch (value.data.codResult) {
            case 0:
              switch (tipo) {
                case 'A':
                  toast.success('Acordo gerado com sucesso.');
                  break;
                case 'S':
                  toast.success('Simulação gerada com sucesso.');
                  break;
                case 'SP':
                  toast.success('Proposta gerada com sucesso. Foi enviado um SMS para o aprovador.');
                  break;
                default:
                  break;
              }
              pesquisarAcordos(0, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas);
              getDadosResumo(idProcesso).then((responseData) => {
                const { data } = responseData;
                setInfoAdicionaisAcordo(data);
              });
              setOpenButtonNovoAcordo(false);
              updatePage();
              break;
            case 1:
              if ((tipo === 'A' || tipo === 'S' || tipo === 'SP') && value.data.msgResult === 'O valor definido para o Acordo difere da soma das parcelas. Revise os valores e tente novamente.') {
                setMsgErroAoGerarSimulacaoAcordo(value.data.msgResult);
                setOpenDialogErroSomatoriaAoGerarAcordo(true);
                break;
              } else if (tipo === 'A' && value.data.msgResult === 'OK') {
                toast.success('Acordo gerado com sucesso.');
              } else if (tipo === 'S' && value.data.msgResult === 'OK') {
                toast.success('Simulação gerada com sucesso.');
              } else if (tipo === 'SP' && value.data.msgResult === 'Proposta gerada com sucesso. Foi enviado um SMS para o aprovador.') {
                toast.success('Proposta gerada com sucesso. Foi enviado um SMS para o aprovador.');
              } else {
                toast.warn(value.data.msgResult);
              }
              pesquisarAcordos(0, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas);
              getDadosResumo(idProcesso).then((responseData) => {
                const { data } = responseData;
                setInfoAdicionaisAcordo(data);
              });
              setOpenButtonNovoAcordo(false);
              updatePage();
              break;
            case 2:
              toast.warn(value.data.msgResult);
              pesquisarAcordos(0, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas);
              getDadosResumo(idProcesso).then((responseData) => {
                const { data } = responseData;
                setInfoAdicionaisAcordo(data);
              });
              setOpenButtonNovoAcordo(false);
              updatePage();
              break;
            case 1301:
            case 1305:
            case -1:
              setMsgErroAoGerarSimulacaoAcordo(value.data.msgResult);
              setOpenDialogErroSomatoriaAoGerarAcordo(true);
              break;
            default:
              toast.error(value.data.msgResult, { style: { width: '392px' } });
              break;
          }
        }).finally(
          setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false)),
        );
    } catch (error) {
      switch (tipo) {
        case 'A':
          toast.error('Não foi possível realizar o Acordo. Tente novamente.', { style: { width: '392px' } });
          break;
        case 'S':
          toast.error('Não foi possível realizar a Simulação. Tente novamente.', { style: { width: '392px' } });
          break;
        case 'SP':
          toast.error('Não foi possível realizar a Proposta. Tente novamente.', { style: { width: '392px' } });
          break;

        default:
          break;
      }
    }
  }

  const getButtonStatusGerarAcordo = () => ITEMS_ANALISE_ACORDO.every(item => dadosResumo.jsonErros[item.colorProp] !== 'ERRO' && dadosResumo.jsonErros[item.colorProp] !== 'ALERTA');

  const getColorButtonGerarProposta = () => (!getButtonStatusGerarAcordo() ? '#7D69A4' : '#FFAE36');

  const getLabelButtonGerarSimulacaoProposta = () => (!getButtonStatusGerarAcordo() ? 'Gerar Proposta' : 'Gerar Simulação');

  const onClickGerarSimulacao = () => {
    if (getButtonStatusGerarAcordo()) {
      // Simulação
      onClickSubmitGerarSimulacaoAcordo('S');
    } else {
      // Proposta
      onClickSubmitGerarSimulacaoAcordo('SP');
    }
  };

  const onClickGerarAcordo = () => {
    // Acordo
    onClickSubmitGerarSimulacaoAcordo('A');
  };

  const onClickVoltarPasso = useCallback(() => {
    handleBackStep();
    setParcelas([]);
    setDadosDefault({
      ...dadosFormFields, temCampanha: dadosDefault.temCampanha, descricaoCampanha: dadosDefault.descricaoCampanha, instrucaoInicial: dadosDefault.instrucaoInicial,
    });
  }, [handleBackStep, setParcelas, setDadosDefault, dadosFormFields, dadosDefault]);

  useEffect(() => {
    dispatch(findTiposFormaPagamentoAction());
    dispatch(findUsuariosPermitemSimulacaoAction());
    dispatch(findTiposTelefoneAction());
  }, [dispatch]);


  const maskTelefone = () => {
    if (telefone.numero?.startsWith('0')) {
      return SERVICO_MASK;
    }
    if (telefone.numero?.startsWith('1')
      || telefone.numero?.startsWith('2')
      || telefone.numero?.startsWith('3')
      || telefone.numero?.startsWith('4')
      || telefone.numero?.startsWith('5')) {
      return RESIDENCIAL_MASK;
    }
    if (telefone.numero?.startsWith('6')
      || telefone.numero?.startsWith('7')
      || telefone.numero?.startsWith('8')
      || telefone.numero?.startsWith('9')) {
      return CELPHONE_MASK;
    }
    return null;
  };

  return (
    <>
      <DialogNew
        open={openDialogErroSomatoriaAoGerarAcordo}
        className={passo3Styles.dialogErroAoGerarAcordo}
      >
        {msgErroAoGerarSimulacaoAcordo}
        <div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button onClick={() => setOpenDialogErroSomatoriaAoGerarAcordo(false)}>
              Ok, ENTENDI
            </Button>
          </div>
        </div>
      </DialogNew>
      <ContainerNovoAcordo>
        <DialogNew
          open={openDialogNovaAlterarParcela}
          text="Alterar Parcela"
          className={passo3Styles.dialogNovaParcela}
          onClose={() => setopenDialogNovaAlterarParcela(false)}
        >
          {openDialogNovaAlterarParcela && (
            <DialogNovaAlterarParcela
              setopenDialogNovaAlterarParcela={setopenDialogNovaAlterarParcela}
              parcelas={parcelas}
              setParcelas={setParcelas}
              clickedParcelaOnEdit={clickedParcelaOnEdit}
              reOrderParcelas={reOrderParcelas}
              arrowDirectionVencimento={arrowDirectionVencimento}
              reNumberParcelas={reNumberParcelas}
              setLoadings={setLoadings}
              parametrosInternosAbe={parametrosInternosAbe}
              dadosPadraoForm={dadosPadraoForm}
              dadosResumo={dadosResumo}
              dadosFormFields={dadosFormFields}
              dataFinalPasso3={dataFinalPasso3}
              formaPagamentoPasso2={dadosFormFields.idTipoFormaPagto}
              calculateTotalParcelas={calculateTotalParcelas}
            />
          )}
        </DialogNew>
        <StepSubLabel className={utilStyles.ml32}>
          Confira os valores, formas de pagamentos e quantidade de parcelas para o Acordo. Confirme o Acordo/Simulação ou refaça os passos anteriores.
        </StepSubLabel>
        <Passo3Body>
          <TableDefault
            columnsHead={(
              <TableHeaderPassoTres
                arrowDirection={arrowDirectionVencimento}
                columnOrdenacao={columnOrdenacaoVencimento}
                setColumnOrdenacao={setColumnOrdenacaoVencimento}
                onClickColumnVencimento={onClickColumnVencimento}
              />
            )}
            footerTableBody={(
              <TableFooterPassoTres
                calculateTotalParcelas={calculateTotalParcelas}
              />
            )}
            showTablePagination={false}
            showTableFooter
            totalElements={parcelas.length}
            totalColumns={5}
          >
            {parcelas?.map((parcela, i) => (
              <TableBodyPassoTres
                content={parcela}
                key={i}
                handleClickButtonAlterarParcela={handleClickButtonAlterarParcela}
                findValuePagamento={findValuePagamento}
                formaPagamentoPasso2={dadosFormFields.idTipoFormaPagto}
              />
            ))}
          </TableDefault>
          <Grid24 container direction="row" style={{ marginTop: '6px' }}>
            <Grid24 xs={24} sm={19} spacing={1} direction="column">
              <Typography className={passo3Styles.totalParcelasText} style={{ marginRight: 3 }}>
                Informar o telefone de contato do Devedor:
              </Typography>
              <Grid24 container direction="row" spacing={1} style={{ marginTop: '6px' }}>
                <Grid24 xs={24} sm={3}>
                  <SelectorField
                    isForm
                    fullWidth
                    label="Tipo Telefone"
                    items={tiposTelefone}
                    name="tipoTelefone"
                    value={telefone.tipoTelefone}
                    onChange={(event) => { setTelefone({ ...telefone, tipoTelefone: event.target.value }); }}
                    // onFocus={focusAttributeHandler}
                  />
                </Grid24>
                <Grid24 xs={24} sm={1}>
                  <MaskField
                    isForm
                    fullWidth
                    label="DDD"
                    name="ddd"
                    value={telefone.ddd}
                    onChange={(event) => { setTelefone({ ...telefone, ddd: event.target.value }); }}
                    format="###"
                  />
                </Grid24>
                <Grid24 xs={24} sm={3}>
                  <MaskField
                    isForm
                    fullWidth
                    label="Telefone"
                    name="numero"
                    value={telefone.numero}
                    maxLength={14}
                    onChange={(event) => { setTelefone({ ...telefone, numero: event.target.value }); }}
                    format={maskTelefone()}
                  />
                </Grid24>
              </Grid24>
            </Grid24>
            <Grid24 xs={24} sm={5} direction="column" alignItems="flex-start">
              <Typography className={passo3Styles.totalParcelasText} style={{ marginRight: 3 }}>
                Valores Acordados:
              </Typography>
              <div className={passo3Styles.valorAcordadoItem}>
                <span>
                  Quantidade de parcelas:
                </span>
                <span>
                  {dadosFormFields.quantidadeParcelas}
                </span>
              </div>
              <div className={passo3Styles.valorAcordadoItem}>
                <span>
                  Valor do acordo:
                </span>
                <span>
                  {getMoneyMask(dadosResumo.valorTotal)}
                </span>
              </div>
            </Grid24>
          </Grid24>
          {!getButtonStatusGerarAcordo()
            && (

              <Grid24 container direction="row" className={passo3Styles.boxPermissaoProposta}>
                <Grid24 xs={24} sm={24}>
                  <SelectorField
                    isForm
                    style={{ width: '35%' }}
                    label={getRequiredLabel('Selecione o usuário para aprovar a Proposta', true)}
                    items={usuariosPermitemProposta}
                    value={idUsuarioSelecionadoPermiteProposta}
                    onChange={e => setIdUsuarioSelecionadoPermiteProposta(e.target.value)}
                  />
                </Grid24>
                <p style={{ marginTop: '15px', marginBottom: '15px', color: '#707070' }}>
                  Insira aqui a justificativa para a aprovação da Proposta*
                </p>
                <Grid24 xs={24} sm={24}>
                  <TextField
                    name="justificativa"
                    id="outlined-multiline-static"
                    label="Justificativa"
                    className={passo3Styles.boxJustificativa}
                    value={justificativa}
                    onChange={e => setTextoJutificativa(e.target.value)}
                    rows={4}
                    multiline
                    InputProps={{
                      style: { fontSize: '14px' },
                    }}
                  />
                </Grid24>
              </Grid24>
            )
          }
        </Passo3Body>
        <Passo3Footer className={utilStyles.mt22}>
          <Passo3FooterActions className={utilStyles.w50}>
            <ButtonUI
              color="white"
              onClick={onClickVoltarPasso}
              className={passo3Styles.footerButtons}
            >
              Voltar
            </ButtonUI>
            <ButtonUI
              color="custom"
              customColor={getColorButtonGerarProposta()}
              onClick={onClickGerarSimulacao}
              className={passo3Styles.footerButtons}
              disabled={!getButtonStatusGerarAcordo() ? (telefone.numero === '' || idUsuarioSelecionadoPermiteProposta === null || idUsuarioSelecionadoPermiteProposta === 0
                || idUsuarioSelecionadoPermiteProposta === '' || idUsuarioSelecionadoPermiteProposta === undefined || justificativa === '') : telefone.numero === ''}
            >
              {getLabelButtonGerarSimulacaoProposta()}
            </ButtonUI>
            <ButtonUI
              color="custom"
              customColor="#52b656"
              onClick={onClickGerarAcordo}
              className={passo3Styles.footerButtons}
              disabled={!getButtonStatusGerarAcordo() || telefone.numero === ''}
            >
              Gerar Acordo
            </ButtonUI>
          </Passo3FooterActions>
        </Passo3Footer>
      </ContainerNovoAcordo>
    </>
  );
}

export const PassoTres = NovoAcordoPassoTres;
