import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { findFasesWhatsappAction } from '../../store/Global/LoadSelectors/loadSelectors.saga';
import WrapperSubTitleForm from '../../components/UI/WrapperSubTitleForm';
import Grid24 from '../../components/UI/Grid24';
import { GroupDefinitions } from '../../components/Cliente/Crud/Tabs/BaseCalculo/Honorarios/styles';
import RadioField from '../../components/UI/Field/Radio/RadioField';
import useStyles, { Container } from './gerarArquivoWhatsApp.styles';
import useUtilStyles from '../../helpers/styles/utils.styles';
import { ButtonUI, InputField, SelectorField } from '../../components';
import { getRequiredLabel } from '../../helpers';
import { useGerarArquivoWhatsAppDispatch } from '../../store/GerarArquivoWhatsApp/GerarArquivoWhatsAppProvider';
import { gerarArquivoWhatsAppAction } from '../../store/GerarArquivoWhatsApp/GerarArquivoWhatsApp.saga';

function GerarArquivoWhatsAppComponent() {
  const globalDispatch = useDispatch();
  const dispatch = useGerarArquivoWhatsAppDispatch();
  const styles = useStyles();
  const utilStyles = useUtilStyles();

  const allFases = useSelector(states => states.selectors.fases);

  const [statusContatoDefinition, setStatusContatoDefinition] = useState('0');

  const [idFaseSelecionada, setIdFaseSelecionada] = useState(1);

  const [telefoneDefinition, setTelefoneDefinition] = useState('0');
  const [nomeArquivo, setNomeArquivo] = useState('');

  const onChangeStatusContatoDefinitionHandler = useCallback((e) => {
    const { value } = e.target;
    setStatusContatoDefinition(value);
  }, []);

  const onChangeTelefoneDefinitionHandler = useCallback((e) => {
    const { value } = e.target;
    setTelefoneDefinition(value);
  }, []);

  const refreshCampos = useCallback(() => {
    setStatusContatoDefinition('0');
    setIdFaseSelecionada(1);
    setTelefoneDefinition('0');
    setNomeArquivo('');
  }, []);

  const gerarArquivoWhatsApp = useCallback(() => {
    const payload = {
      statusContatoDefinition,
      idFaseSelecionada,
      telefoneDefinition,
      nomeArquivo: `${nomeArquivo}.xlsx`,
    };
    dispatch(gerarArquivoWhatsAppAction(payload));

    refreshCampos();
  }, [dispatch,
    statusContatoDefinition,
    idFaseSelecionada,
    telefoneDefinition,
    nomeArquivo,
    refreshCampos,
  ]);

  useEffect(() => {
    globalDispatch(findFasesWhatsappAction(true));
  }, [globalDispatch]);

  return (
    <>
      <Container>
        <Grid24 container isFormContainer direction="row" spacing="1">
          <WrapperSubTitleForm title="Escolha as opções para geração do arquivo:" className={styles.titleTextoPadrao} />
          <Grid24 container xs={12} sm={12} isFormContainer spacing="1">
            <Grid24 item xs={24} className={utilStyles.mt9}>
              <Typography component="span" className={styles.infos__gerais}>
                <span>Status do Contato:</span>
              </Typography>
            </Grid24>
            <Grid24 item xs={24} direction="column" className={utilStyles.mt5}>
              <GroupDefinitions
                aria-label="status-contato"
                value={statusContatoDefinition}
                onChange={onChangeStatusContatoDefinitionHandler}
              >
                <RadioField label="Pós Processamento do Borderô" value="0" />
                <RadioField label="Processos em uma fase específica" value="1" />
              </GroupDefinitions>
              {statusContatoDefinition === '1' && (
              <Grid24 item xs={24} sm={8} className={styles.mt9ml40}>
                <SelectorField
                  isForm
                  label="Fase Selecionada"
                  name="idFase"
                  value={idFaseSelecionada}
                  items={allFases.selector}
                  loading={allFases.loading}
                  onChange={(event) => { setIdFaseSelecionada(event.target.value); }}
                />
              </Grid24>
              )}

            </Grid24>
          </Grid24>
          <Grid24
            container
            xs={12}
            sm={12}
            isFormContainer
            spacing="1"
            style={{
              flexFlow: 'column',
            }}
          >
            <Grid24 item xs={24} className={utilStyles.mt9}>
              <Typography component="span" className={styles.infos__gerais}>
                <span>Telefone:</span>
              </Typography>
            </Grid24>
            <Grid24 item xs={24} direction="column" className={utilStyles.mt5}>
              <GroupDefinitions
                aria-label="telefone"
                value={telefoneDefinition}
                onChange={onChangeTelefoneDefinitionHandler}
              >
                <RadioField label="Incluir todos os telefones do Devedor" value="0" />
                <RadioField label="Incluir somente o telefone principal do Devedor" value="1" />
              </GroupDefinitions>
            </Grid24>
          </Grid24>
        </Grid24>
        <Grid24 item xs={24} sm={17} className={utilStyles.mt20}>
          <InputField
            fullWidth
            isForm
            label={getRequiredLabel('Nome do Arquivo', true)}
            name="nomeArquivo"
            value={nomeArquivo}
            onChange={(event) => { setNomeArquivo(event.target.value); }}
          />
        </Grid24>
        <div className={styles.div__align}>
          <div className={styles.div__buttons}>
            <ButtonUI
              disabled={nomeArquivo === ''}
              onClick={gerarArquivoWhatsApp}
            >
              Gerar
            </ButtonUI>
          </div>
        </div>
      </Container>
    </>
  );
}

export const GerarArquivoWhatsApp = withRouter(GerarArquivoWhatsAppComponent);
