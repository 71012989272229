import {
  call, takeLatest,
} from 'redux-saga/effects';
import { GERAR_ARQUIVO_WHATSAPP_ACTION } from './GerarArquivoWhatsApp.constants';
import { downloadFileSispag, toastUnmappedException } from '../../helpers';
import GerarArquivoWhatsAppService from '../../services/core/gerarArquivoWhatsApp/GerarArquivoWhatsAppService';

export const gerarArquivoWhatsAppAction = payload => ({
  type: GERAR_ARQUIVO_WHATSAPP_ACTION,
  payload,
});

function* gerarArquivoWhatsAppHandler(actions) {
  const { payload } = actions;

  try {
    const response = yield call(GerarArquivoWhatsAppService.gerarArquivoWhatsApp, payload);
    yield downloadFileSispag(payload.nomeArquivo, response.data);
  } catch (e) {
    toastUnmappedException(
      e,
      'Ocorreu um problema ao tentar abrir o relatório',
    );
  }
}

export default function* watchEmailEntradaBordero() {
  yield takeLatest(GERAR_ARQUIVO_WHATSAPP_ACTION, gerarArquivoWhatsAppHandler);
}
