import React, { createContext } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider, createSelectorHook, createDispatchHook } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './GerarArquivoWhatsApp.store';
import watchGerarArquivoWhatsApp from './GerarArquivoWhatsApp.saga';

export const gerarArquivoWhatsAppContext = createContext();
export const useGerarArquivoWhatsAppSelector = createSelectorHook(gerarArquivoWhatsAppContext);
export const useGerarArquivoWhatsAppDispatch = createDispatchHook(gerarArquivoWhatsAppContext);

export default function GerarArquivoWhatsAppProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchGerarArquivoWhatsApp);

  return (
    <Provider context={gerarArquivoWhatsAppContext} store={store}>
      {children}
    </Provider>
  );
}
