import { v4 as uuid } from 'uuid';
import { MdSend, MdEmail } from 'react-icons/md';
import {
  FaCalendarCheck, FaFlag, FaQuoteLeft, FaUserEdit,
} from 'react-icons/fa';
import { PiUserSwitchFill } from 'react-icons/pi';

import { WhatsApp } from '@material-ui/icons';
import { extractInputsDatasFromForm, updateFieldsWithErrors, extractResponseErrorMessage } from '../../helpers/utils/form.utils';
import {
  checkPermission, isBadRequestException, toAmericanDate, toBrazilianDate,
} from '../../helpers/utils/utils';
import { tipoExibicaoAcompanhamento } from '../../models/types';
import { acompanhamentoProcessoController } from '../../components/UI/AcompanhamentoProcesso/acompanhamentoProcessoController';
import { tabUiController } from '../../controller/tabsUi.controller';
import { AcaoEnviar } from './AcaoEnviar/AcaoEnviar';
import { AcaoAnotar } from './AcaoAnotar/AcaoAnotar';
import { AcaoRequisicao } from './AcaoRequisicao/AcaoRequisicao';
import { AcaoEnviarConsultaCliente } from './AcaoEnviarConsultaCliente/AcaoEnviarConsultaCliente';
import { AcaoAgendarCompromisso } from './AcaoAgendarCompromisso/AcaoAgendarCompromisso';
import { findAllFases } from '../../services/core/fase/fase.services';

import { tipoUsuarioComercialEnum } from '../../helpers/constants/enums.constants';

import store from '../../store/createStore';

import {
  findAutoresAcompanhamentos,
  findCategoriasAction,
  findEstadosAction,
  findFiliaisAction,
  findMotivosBaixaAction,
  findRamosAtividadesAction,
  findRequisicoesAction,
  findTiposAcompanhamentoProcesso,
  findTiposAgendamentosAction,
  findTiposTelefoneAction,
  findUsuariosComercialPrincipalClienteAction,
  findUsuariosTelemarketingAction,
  findTiposTitulosAgendamentosAction,
} from '../../store/Global/LoadSelectors/loadSelectors.saga';
import { buscarPreferenciaInspecaoService } from '../../services/core/preferenciainspecao/preferenciaInspecaoService';
import { findFasesTimelineProcessoService } from '../../services/core/processos/processosFaseService';
import { buscarDadosDevedor } from '../../services/core/processos/processosDevedorService';
import {
  buscarCadastroCliente, buscarResumoDoProcessoService, buscarWarningsDevedorProcesso, findInformacoesAdicionaisInpescaoProcessoService, updateTipoExibicaoAcompanhamento,
} from '../../services/core/processos/processosService';
import AcaoEnviarEmailDevedor from './AcaoEnviarEmailDevedor/AcaoEnviarEmailDevedor';
import { buscarIdTipoUsuarioTelemarketing } from '../../services/core/usuario/usuario.services';
import AcaoAlterarNegociador from './AcaoAlterarNegociador/AcaoAlterarNegociador';
import AcaoReenviarWhatsapp from './AcaoReenviarWhatsapp/AcaoReenviarWhatsapp';


export const actionProps = {
  enviar: {
    tag: 'enviar',
    makeActionProps: (preveiwActions, values = {}) => ({
      ...preveiwActions,
      motivosBaixa: values?.motivosBaixa || [],
      permiteCobrancaPresencial: values?.permiteCobrancaPresencial || false,
      idFaseAtual: values?.idFaseAtual,
      idFasePastaAtual: values?.idFasePastaAtual,
      idMotivoBaixa: values?.idMotivoBaixa,
      idNegociador: values?.idNegociador,
      temAgendamento: values?.temAgendamento,
      idProcesso: values?.idProcesso,
      clientePermiteUnificar: values?.clientePermiteUnificar,
      dadosProcesso: values?.dadosProcesso,
      fasesIds: values?.fasesIds,
      podeModificarFase: values?.podeModificarFase,
      idFase: values?.idFase,
      disabledAntecedentes: values?.disabledAntecedentes,
    }),
  },
  anotar: {
    tag: 'anotar',
    makeActionProps: (preveiwActions, values = {}) => ({
      ...preveiwActions,
      tiposAcompanhamento: values?.tiposAcompanhamento || [],
      idProcesso: values?.idProcesso,
      clientePermiteUnificar: values?.clientePermiteUnificar,
      dadosProcesso: values?.dadosProcesso,
      fasesIds: values?.fasesIds,
      podeModificarFase: values?.podeModificarFase,
      idFase: values?.idFase,
      idFaseAtual: values?.idFaseAtual,
      disabledAntecedentes: values?.disabledAntecedentes,
    }),
  },
  gerarRelatorio: {
    tag: 'gerarRelatorio',
    makeActionProps: preveiwActions => ({ ...preveiwActions }),
  },
  gerarRequisicao: {
    tag: 'gerarRequisicao',
    makeActionProps: (preveiwActions, values = {}) => ({
      ...preveiwActions,
      requisicoes: values?.requisicoes || [],
      temPermissaoAtenderRequisicao: true, // values?.temPermissaoAtenderRequisicao || true,
    }),
  },
  enviarConsultaCliente: {
    tag: 'enviarConsultaCliente',
    makeActionProps: preveiwActions => ({
      ...preveiwActions,
    }),
  },
  agendarCompromisso: {
    tag: 'agendarCompromisso',
    makeActionProps: (preveiwActions, values = {}) => ({
      ...preveiwActions,
      tiposAgendamentos: values?.tiposAgendamentos || [],
      idProcesso: values?.idProcesso,
      clientePermiteUnificar: values?.clientePermiteUnificar,
      dadosProcesso: values?.dadosProcesso,
      fasesIds: values?.fasesIds,
      podeModificarFase: values?.podeModificarFase,
      idFase: values?.idFase,
      idFaseAtual: values?.idFaseAtual,
      disabledAntecedentes: values?.disabledAntecedentes,
      tiposTitulosAgendamentos: values?.tiposTitulosAgendamentos || [],
    }),
  },
  enviarEmailDevedor: {
    tag: 'enviarEmailDevedor',
    makeActionProps: (preveiwActions, values = {}) => ({
      ...preveiwActions,
      idProcesso: values?.idProcesso,
      clientePermiteUnificar: values?.clientePermiteUnificar,
      dadosProcesso: values?.dadosProcesso,
      fasesIds: values?.fasesIds,
      podeModificarFase: values?.podeModificarFase,
      idFase: values?.idFase,
      idFaseAtual: values?.idFaseAtual,
      disabledAntecedentes: values?.disabledAntecedentes,
    }),
  },
  alterarNegociador: {
    tag: 'alterarNegociador',
    makeActionProps: preveiwActions => ({
      ...preveiwActions,
    }),
  },
  reenviarWhatsapp: {
    tag: 'reenviarWhatsapp',
    makeActionProps: (preveiwActions, values = {}) => ({
      ...preveiwActions,
      idFaseAtual: values?.idFaseAtual,
      idProcesso: values?.idProcesso,
      podeReenviarWhatsapp: values?.podeReenviarWhatsapp,
    }),
  },
};


class InspecionarProcessoPageController {
  constructor() {
    this.acompanhamentoFilters = {};
  }

  init() {
    this.acompanhamentoFilters = {};
  }


  changeLoading(logingTags, enabled) {
    return oldValue => ({ ...oldValue, [logingTags]: enabled });
  }

  async loadImmutableInfo() {
    store.dispatch(findTiposTelefoneAction());
    store.dispatch(findCategoriasAction());
    store.dispatch(findFiliaisAction());
    store.dispatch(findRamosAtividadesAction());
    store.dispatch(findMotivosBaixaAction());
    store.dispatch(findEstadosAction());
    store.dispatch(findTiposAgendamentosAction());
    store.dispatch(findRequisicoesAction());
    store.dispatch(findTiposAcompanhamentoProcesso());
    store.dispatch(findTiposTitulosAgendamentosAction());
    try {
      const [fases] = await Promise.all([findAllFases()]);
      return {
        fases: this.makeFases(fases.data),
      };
    } catch {
      return {
        fases: [],
      };
    }
  }

  async loadComplementaryInfoForCliente(idCliente, idUsuarioTelemarketing) {
    if (idCliente) {
      store.dispatch(findUsuariosComercialPrincipalClienteAction(idCliente, tipoUsuarioComercialEnum.PRINCIPAL));
      store.dispatch(findUsuariosTelemarketingAction(idUsuarioTelemarketing));
    }
  }

  async loadMutableInfo(idProcesso, permitirUnificarProcesso) {
    store.dispatch(findAutoresAcompanhamentos(idProcesso));
    const requisicoesPermitidasUsuario = store.getState()?.authentication?.userInformation?.requisicoes || [];
    try {
      const [cliente, infoProcesso, devedor, devedorWarnings, fasesTimeline, dadosProcesso, idUsuarioTelemarketing] = await Promise.all([
        buscarCadastroCliente(idProcesso),
        findInformacoesAdicionaisInpescaoProcessoService(idProcesso, requisicoesPermitidasUsuario, permitirUnificarProcesso),
        buscarDadosDevedor(idProcesso),
        buscarWarningsDevedorProcesso(idProcesso),
        findFasesTimelineProcessoService(idProcesso),
        buscarResumoDoProcessoService(idProcesso),
        buscarIdTipoUsuarioTelemarketing(),
      ]);

      if (cliente) {
        await this.loadComplementaryInfoForCliente(cliente.data.idCliente, idUsuarioTelemarketing.data);
      }

      return {
        fasesTimeline: fasesTimeline.data,
        cliente: cliente.data,
        devedor: devedor.data,
        devedorWarnings: devedorWarnings.data,
        infoProcesso: infoProcesso.data,
        actionsButtons: this.makeActionsButtons(infoProcesso.data, store.getState().authentication.userInformation.permissoes),
        dadosProcesso: dadosProcesso.data,
      };
    } catch (e) {
      return {
        cliente: {},
        devedor: {},
        devedorWarnings: [],
        infoProcesso: {},
        preferencias: [],
        actionsButtons: [],
      };
    }
  }

  async findPreferencias() {
    try {
      const preferencias = await buscarPreferenciaInspecaoService();
      return {
        preferencias: preferencias.data,
        visibilidadePreferencias: preferencias.data.reduce((acc, preferencia) => ({
          ...acc,
          [preferencia?.idPreferenciaInspecao]: preferencia?.isVisivel || !preferencia?.permitirSelecionar,
        }), {}),
      };
    } catch {
      return {
        preferencias: {},
        visibilidadePreferencias: {},
      };
    }
  }

  async findAcompanhamentosWhenSubmit(idProcesso, targetElement, dataCadastramento, errors) {
    this.acompanhamentoFilters = extractInputsDatasFromForm(targetElement);
    this.acompanhamentoFilters.dataCadastramento = null;
    if (dataCadastramento) {
      this.acompanhamentoFilters.dataCadastramento = toAmericanDate(dataCadastramento);
    }

    const data = await acompanhamentoProcessoController.findAllFromPage(idProcesso, this.acompanhamentoFilters);
    if (data.error && errors) {
      const [updatedErrors] = updateFieldsWithErrors(data.error, errors);
      data.error = updatedErrors;
    }
    return data;
  }

  async findAcompanhementWhenReset(idProcesso) {
    this.acompanhamentoFilters = {};
    const data = await acompanhamentoProcessoController.findAllFromPage(idProcesso, this.acompanhamentoFilters);
    return data.acompanhamentos;
  }

  async findAcompanhamentoWhenScrolled(idProcesso, oldersAcompanhamentos) {
    const data = await acompanhamentoProcessoController.findAllFromNextPage(idProcesso, oldersAcompanhamentos, this.acompanhamentoFilters);
    return data.acompanhamentos;
  }

  async udpateAcompanhamentosWhenChecked(idProcesso, idProcessoAcompanhamento, checked) {
    const tipoExibicao = checked ? tipoExibicaoAcompanhamento.fixo : tipoExibicaoAcompanhamento.naoFixo;
    try {
      await updateTipoExibicaoAcompanhamento(idProcesso, idProcessoAcompanhamento, tipoExibicao);
      const data = await acompanhamentoProcessoController.findAllFromPage(idProcesso, this.acompanhamentoFilters);
      return {
        acompanhamentos: data.acompanhamentos,
        checked: true,
      };
    } catch (err) {
      if (isBadRequestException(err)) {
        return { checked: false, error: extractResponseErrorMessage(err) };
      }
      return { checked: false, error: 'Erro não mapeado' };
    }
  }

  changeActionButton(actionsButtons, selectedTag) {
    return actionsButtons.map((action) => {
      if (action.tag === selectedTag) {
        action.active = !action.active;
      } else {
        action.active = false;
      }
      return action;
    });
  }

  podeAtenderRequisicao(values) {
    return !(values?.podeModificarFase
      || (!values?.podeModificarFase && values?.temPermissaoAtenderRequisicao));
  }

  isLoading(loadings) {
    return Object.keys(loadings).some(key => loadings[key] === true);
  }

  makeIdsProcessosTab(idsProcessos = []) {
    return idsProcessos.map((id => tabUiController.makeItem(id)));
  }

  makeDefaultDataCadastramento(numeroDias) {
    if (!numeroDias) {
      return '';
    }

    const date = new Date();
    date.setDate(date.getDate() - numeroDias);
    return toBrazilianDate(date);
  }

  makeDefaultDropdownAcompanhamento() {
    return {
      idTipoAcompanhamento: '',
      idFase: '',
      idUsuario: '',
    };
  }

  makeDefaultAcompanhamentoSearchErrors() {
    return {
      dataCadastramento: {
        error: false,
        errorMessage: '',
      },
    };
  }

  makeMuttableSelectors(states) {
    return {
      autoresAcompanhamentos: states.selectors.autoresAcompanhamentos.selector,
    };
  }

  makeImmutableSelectors(states) {
    return {
      tiposAcompanhamento: states.selectors.tiposAcompanhamentoProcesso.selector,
      categorias: states.selectors.categorias.selector,
      filiais: states.selectors.filiais.selector,
      ramosAtividades: states.selectors.ramosAtividades.selector,
      tipoUsuarioComercialPrincipal: states.selectors.tipoUsuarioComercialPrincipal.selector,
      tipoUsuarioComercialTelemarketing: states.selectors.tipoUsuarioComercialTelemarketing.selector,
      estados: states.selectors.estados.selector,
      tiposTelefone: states.selectors.tiposTelefone.selector,
      tiposAgendamentos: states.selectors.tiposAgendamentos.selector,
      motivosBaixa: states.selectors.motivosBaixa.selector,
      requisicoes: states.selectors.requisicoes.selector,
      tipoTitulo: states.selectors.tipoTitulo.selector,
      tiposTitulosAgendamentos: states.selectors.tiposTitulosAgendamentos.selector,
    };
  }

  makeLoadingSelectors(states) {
    return states.selectors.fases.loading
      || states.selectors.autoresAcompanhamentos.loading
      || states.selectors.tiposAcompanhamentoProcesso.loading
      || states.selectors.motivosBaixa.loading
      || states.selectors.requisicoes.loading;
  }

  makeActionsButtons(values, userPermissions) {
    return [
      {
        key: uuid(),
        label: 'Enviar',
        active: false,
        tag: actionProps.enviar.tag,
        Icon: MdSend,
        styles: { transform: 'translateX(1px)' },
        Content: AcaoEnviar,
        disabled: !values?.podeModificarFase,
      },
      {
        key: uuid(),
        label: 'Anotar',
        active: false,
        tag: actionProps.anotar.tag,
        Icon: FaQuoteLeft,
        Content: AcaoAnotar,
        disabled: !values?.podeModificarFase,
      },
      {
        key: uuid(),
        label: values?.temRequisicao ? 'Atender Requisição' : 'Gerar Requisição',
        active: false,
        tag: actionProps.gerarRequisicao.tag,
        Icon: FaFlag,
        Content: AcaoRequisicao,
        disabled: this.podeAtenderRequisicao(values),
      },
      {
        key: uuid(),
        label: 'Enviar Consulta ao Cliente',
        active: false,
        tag: actionProps.enviarConsultaCliente.tag,
        Icon: FaUserEdit,
        styles: { transform: 'translateX(2px) scale(1.3, 1.3)' },
        Content: AcaoEnviarConsultaCliente,
        disabled: !values?.podeModificarFase,
      },
      {
        key: uuid(),
        label: 'Agendar Compromisso',
        active: false,
        tag: actionProps.agendarCompromisso.tag,
        Icon: FaCalendarCheck,
        Content: AcaoAgendarCompromisso,
        disabled: !values?.podeModificarFase,
      },
      {
        key: uuid(),
        label: 'Enviar e-mail ao Devedor',
        active: false,
        tag: actionProps.enviarEmailDevedor.tag,
        Icon: MdEmail,
        styles: { transform: 'scale(1.15, 1.15)' },
        Content: AcaoEnviarEmailDevedor,
        disabled: !values?.podeModificarFase,
      },
      {
        key: uuid(),
        label: 'Alterar Negociador',
        active: false,
        tag: actionProps.alterarNegociador.tag,
        Icon: PiUserSwitchFill,
        styles: { transform: 'scale(1.4, 1.4)' },
        Content: AcaoAlterarNegociador,
        disabled: (!values?.podeModificarFase || checkPermission(userPermissions, 1208)),
      },
      {
        key: uuid(),
        label: 'Reenviar Whatsapp',
        active: false,
        tag: actionProps.reenviarWhatsapp.tag,
        Icon: WhatsApp,
        styles: { transform: 'scale(1, 1)' },
        Content: AcaoReenviarWhatsapp,
        disabled: (values?.podeReenviarWhatsapp?.length === 0),
      },
    ];
  }

  makeFases(response, permiteReceberProcesso = null) {
    if (!response) {
      return [];
    }
    return response.filter(fase => permiteReceberProcesso === null || fase?.permiteReceberProcesso === permiteReceberProcesso)
      .map(fase => ({
        id: fase?.idFase || '',
        value: fase?.nome || '',
        exigirMotivoBaixa: fase?.exigirMotivoBaixa || false,
      }));
  }
}

export const inspecionarProcessoPageController = new InspecionarProcessoPageController();
