import { call, put, takeLatest } from 'redux-saga/effects';
import {
  buscarNegociadoresDaFaseService,
  getPerfiesUsuario,
  getShortUsuarios,
  getTiposUsuario,
  getTiposUsuariosCliente,
  getTiposUsuariosNegociador,
} from '../../../services/core/usuario/usuario.services';
import { getCentroCusto } from '../../../services/core/centroCusto/centroCusto.services';
import { getCategorias } from '../../../services/core/categoria/categoria.services';
import { getEstados } from '../../../services/core/estado/estado.services';
import { getTipoTelefone, getTipoTelefoneParaCliente } from '../../../services/core/telefone/telefone.services';
import {
  findTiposTitulo,
  getBorderoTipoStatus,
  getTiposLogProcessamentoBordero,
} from '../../../services/bordero/bordero/bordero.services';
import { getShortFiliais } from '../../../services/core/filial/filial.services';
import { buildCategoriasSelector } from '../../../services/core/categoria/categoria.factory';
import { buildSelectorFromTipoTelefoneResponse } from '../../../services/core/telefone/telefone.factory';
import { buildSelectorFromEstadosResponse } from '../../../services/core/estado/estado.factory';
import { buildTiposTituloSelector } from '../../../services/bordero/bordero/border.factory';
import { setLoadingAction, setSelectorDataAction } from './loadSelectors.store';
import {
  buildBancos,
  buildBorderoTipoStatus,
  buildCentrosCusto,
  buildContasBancarias,
  buildDefaultSelector,
  buildFases,
  buildFasesPastas,
  buildFiliais,
  buildGeradoresRelatorios,
  buildIntervaloPagamento,
  buildModeloNotificacaoEmail,
  buildMotivosBaixa,
  buildMotivosExclusaoTitulo,
  buildMotivosQuebraAcordo,
  buildNegociadoresExternosAtivosFase,
  buildNegociadoresHabilitadosProcesso,
  buildPerfies,
  buildPerfisUsuarioCliente,
  buildPrefeiturasNotaFiscal,
  buildRamosAtividade,
  buildRegraCalculoRepasseParametro,
  buildRegraComissionamento,
  buildRequisicoes,
  buildResponsaveisCC,
  buildStatusBorderoPesquisa,
  buildTipoAcordoSeguro,
  buildTipoCalculoComissaoParametro,
  buildTipoCalculoHonorarioParametro,
  buildTipoClassificacaoClientes,
  buildTipoComissaoBordero,
  buildTipoDesconto,
  buildTipoFormaPagamento,
  buildTipoIntervaloPagamento,
  buildTipoPrazoExpiracaoPix,
  buildTiposChavesPix,
  buildTiposConta,
  buildTiposFormasPagamentoRecebimento,
  buildTiposHonorariosContratuaisParametro,
  buildTiposLancamentos,
  buildTiposLiquidacaoPrestacao,
  buildTiposLogProcessamentoBordero,
  buildTiposNotasFiscais,
  buildTiposPrestacao,
  buildTiposTitulosAgendamentos,
  buildTipoTextoPadrao,
  buildTipoTitulo,
  buildTipoUsuario,
  buildUsuarios,
  buildUsuariosPermitemSimulacao,

} from './loadSelectors.factory';
import {
  getBancos,
  getBancoTipoConta,
} from '../../../services/core/banco/banco.services';
import { getShortRamoAtividade } from '../../../services/core/ramoAtividade/ramoAtividade.services';
import {
  getRegraCalculoRepasse,
  getTipoCalculoComissao,
  getTipoCalculoHonorario,
  getTipoPrazoExpiracaoPix,
  getTipoRegraComissionamento,
} from '../../../services/core/parametro/parametro.services';
import { getTipoDescontoCliente } from '../../../services/core/tipoDesconto/tipoDesconto.services';
import {
  getPerfiesCliente,
  findAllTiposPrestacao,
  findAllTiposLiquidacaoPrestacao,
  findAllTiposChavePix,
  findAllTiposNotasFiscais,
  findAllPrefeiturasNotaFiscal,
  getIntervaloPagamento,
  getTipoClassificacaoClientes,
  getModeloNotificacaoEmail,
  getTiposHonorariosContratuais,
} from '../../../services/core/cliente/cliente.services';
import { findAllFases, findAllFasessWhatsapp } from '../../../services/core/fase/fase.services';
import { getAllRequisicoesSimplificadas } from '../../../services/core/requisicoes/requisicoes.services';
import {
  FIND_BANCOS,
  FIND_BORDERO_TIPO_STATUS,
  FIND_CATEGORIAS,
  FIND_CENTRO_CUSTO,
  FIND_ESTADOS,
  FIND_FILIAIS,
  FIND_MOTIVOS_BAIXA,
  FIND_NEGOCIADORES,
  FIND_PASTAS_FASE,
  FIND_PERFIS,
  FIND_PERFIS_USUARIOS_CLIENTE,
  FIND_RAMOS_ATIVIDADES,
  FIND_REGRA_CALCULO_REPASSE_PARAMETRO,
  FIND_REQUISICOES,
  FIND_STATUS_BORDERO_IMPORTACAO,
  FIND_TIPO_ACORDO_SEGURO,
  FIND_TIPO_CALCULO_COMISSAO_PARAMETRO,
  FIND_TIPO_CALCULO_HONORARIO_PARAMETRO,
  FIND_TIPO_COMISSAO_BORDERO,
  FIND_TIPO_DESCONTO,
  FIND_TIPO_FASES,
  FIND_TIPO_PRAZO_EXPIRACAO_PIX,
  FIND_TIPO_USUARIO,
  FIND_TIPO_USUARIO_COMERCIAL_PRINCIPAL,
  FIND_TIPO_USUARIO_COMERCIAL_TELEMARKETING,
  FIND_TIPOS_AGENDAMENTOS,
  FIND_TIPOS_CONTA,
  FIND_TIPOS_LOG_PROCESSAMENTO_BORDERO,
  FIND_TIPOS_TELEFONE,
  FIND_TIPOS_TITULO,
  FIND_USUARIOS,
  FIND_USUARIOS_TELEMARKETING,
  FIND_TIPO_TEXTO_PADRAO,
  FIND_AUTORES_ACOMPANHAMENTO,
  FIND_TIPOS_ACOMPANHAMENTO_PROCESSO,
  FIND_TIPO_TITULO,
  FIND_ALL_TIPOS_CALENDARIO,
  FIND_ALL_RESPONSAVEIS_CONSULTA_DINAMICA,
  FIND_ALL_USUARIOS_COM_PERMISSAO_CONSULTA_DINAMICA,
  FIND_ALL_TIPOS_PRESTACAO,
  FIND_ALL_TIPOS_LIQUIDACAO_PRESTACAO,
  FIND_GERADORES_RELATORIOS_TIPO_PRESTACAO,
  FIND_ALL_TIPOS_CHAVE_PIX,
  TIPO_LANCAMENTOS_ERROR,
  RESPONSAVEIS_ERROR,
  FIND_TIPOS_LANCAMENTOS_ACTION,
  FIND_RESPONSAVEIS_ACTION,
  FIND_PREFEITURAS_NOTA_FISCAL_ACTION,
  FIND_TIPOS_NOTAS_FISCAIS_ACTION,
  FIND_TIPO_FORMA_PAGAMENTO_ACTION,
  FIND_TIPOS_INTERVALO_PAGAMENTO_ACTION,
  FIND_TIPOS_FORMAS_PAGAMENTO,
  FIND_CONTAS_BANCARIAS,
  FIND_TIPO_REGRA_COMISSIONAMENTO,
  FIND_USUARIOS_PERMITEM_SIMULACAO,
  FIND_USUARIOS_NEGOCIADORES_ACTION,
  FIND_INTERVALO_ENTRE_PAGAMENTO,
  FIND_MOTIVOS_QUEBRA_ACORDO_LIST_ACTION,
  FIND_NEGOCIADORES_EXTERNOS_ATIVOS_PARA_FASE,
  FIND_MOTIVOS_EXCLUSAO_TITULO,
  FIND_NEGOCIADORES_VALIDOS,
  FIND_TIPOS_TITULOS_AGENDAMENTOS,
  FIND_TIPO_CLASSIFICACAO_CLIENTES_ACTION,
  FIND_MODELO_NOTIFICACAO_EMAIL,
  FIND_TIPOS_TELEFONE_CLIENTES,
  FIND_TIPO_HONORARIOS_CONTRATUAIS_ACTION,
  FIND_TIPO_FASES_WHATSAPP,
} from './loadSelectors.constans';
import { getTipoAcordoSeguro } from '../../../services/core/acordoseguro/tipoAcordoSeguro.services';
import { getTipoComissaoBordero } from '../../../services/core/comissaobordero/tipoComissaoBordero.services';
import { findPastas } from '../../../services/core/fasePasta/fasePasta.services';
import { getStatusBorderoPesquisa } from '../../../services/bordero/importacao/importacao.service';
import { buscarMotivoBaixa } from '../../../services/core/motivoBaixa/motivoBaixa.service';
import { getTipoTextoPadrao } from '../../../services/textosPadrao/textosPadrao.services';
import { buscarTiposCalendarioService } from '../../../services/core/dashboard/dashboard.services';
import { ConsultaDinamicaService } from '../../../services';
import { findGeradoresRelatoriosTipoPrestacao } from '../../../services/core/relatorio/relatorio.services';
import { findUsuariosPermitemSimulacao, getTiposFormaPagamentoService, motivosQuebraAcordo } from '../../../services/core/processos/processosAcordosService';
import {
  buscarNegociadoresHabilitadosProcesso,
  findAutoresAcompanhamentosService,
  findTiposAcompanhamentosProcessoService,
  getTiposAgendamentoProcessoService,
  getUsuariosNegociadoresProcessoService,
  getTiposTituloAgendamentoProcessoService,
} from '../../../services/core/processos/processosService';
import { buscarMotivosExclusaoTitulo, getTipoTitulo } from '../../../services/core/processos/processosTitulosService';
import {
  findAllResponsaveis,
  findAllTiposDeLancamentos,
} from '../../../services/core/contaCorrenteClienteService/ContaCorrenteClienteService';
import { toastUnmappedException } from '../../../helpers';

import { getTiposIntervaloPagamentoService } from '../../../services/core/tipoIntervaloPagamento/tipoIntervaloPagamento.service';
import RecebimentoClienteService from '../../../services/core/RecebimentoService/RecebimentoClienteService';

export const findTipoAcordoSeguroAction = () => ({
  type: FIND_TIPO_ACORDO_SEGURO,
});

export const findIntervaloPagamentoAction = () => ({
  type: FIND_INTERVALO_ENTRE_PAGAMENTO,
});

export const findModeloNotificacaoEmailAction = () => ({
  type: FIND_MODELO_NOTIFICACAO_EMAIL,
});

export const findTipoComissaoBorderoAction = () => ({
  type: FIND_TIPO_COMISSAO_BORDERO,
});

export const findCategoriasAction = () => ({
  type: FIND_CATEGORIAS,
});

export const findEstadosAction = () => ({
  type: FIND_ESTADOS,
});

export const findTiposTelefoneAction = () => ({
  type: FIND_TIPOS_TELEFONE,
});

export const findTiposTelefoneClientesAction = () => ({
  type: FIND_TIPOS_TELEFONE_CLIENTES,
});

export const findTiposTituloAction = () => ({
  type: FIND_TIPOS_TITULO,
});

export const findUsuariosAction = tipoUsuario => ({
  type: FIND_USUARIOS,
  tipoUsuario,
});

export const findUsuariosTelemarketingAction = tipoUsuario => ({
  type: FIND_USUARIOS_TELEMARKETING,
  tipoUsuario,
});

export const findCentrosCustoAction = () => ({
  type: FIND_CENTRO_CUSTO,
});

export const findTipoUsuarioAction = () => ({
  type: FIND_TIPO_USUARIO,
});

export const findBancosAction = () => ({
  type: FIND_BANCOS,
});

export const findPerfisAction = () => ({
  type: FIND_PERFIS,
});

export const findTiposContaAction = () => ({
  type: FIND_TIPOS_CONTA,
});

export const findFiliaisAction = () => ({
  type: FIND_FILIAIS,
});

export const findTipoClassificacaoClientesAction = () => ({
  type: FIND_TIPO_CLASSIFICACAO_CLIENTES_ACTION,
});

export const findRamosAtividadesAction = () => ({
  type: FIND_RAMOS_ATIVIDADES,
});

export const findPerfisUsuarioClienteAction = () => ({
  type: FIND_PERFIS_USUARIOS_CLIENTE,
});

export const findTipoCalculoHonorarioAction = () => ({
  type: FIND_TIPO_CALCULO_HONORARIO_PARAMETRO,
});

export const findTipoCalculoComissaoAction = () => ({
  type: FIND_TIPO_CALCULO_COMISSAO_PARAMETRO,
});

export const findTipoHonorariosContratuaisAction = () => ({
  type: FIND_TIPO_HONORARIOS_CONTRATUAIS_ACTION,
});

export const findTipoDescontoAction = () => ({
  type: FIND_TIPO_DESCONTO,
});

export const findFasesAction = (permiteReceberProcesso = false) => ({
  type: FIND_TIPO_FASES,
  permiteReceberProcesso,
});

export const findFasesWhatsappAction = (permiteReceberProcesso = false) => ({
  type: FIND_TIPO_FASES_WHATSAPP,
  permiteReceberProcesso,
});

export const findRequisicoesAction = () => ({
  type: FIND_REQUISICOES,
});

export const findRegraCalculoRepassesAction = () => ({
  type: FIND_REGRA_CALCULO_REPASSE_PARAMETRO,
});

export const findUsuariosComercialPrincipalClienteAction = (idCliente, tipoUsuarioComercial) => ({
  type: FIND_TIPO_USUARIO_COMERCIAL_PRINCIPAL,
  idCliente,
  tipoUsuarioComercial,
});

export const findUsuariosComercialMarketingClienteAction = (idCliente, tipoUsuarioComercial) => ({
  type: FIND_TIPO_USUARIO_COMERCIAL_TELEMARKETING,
  idCliente,
  tipoUsuarioComercial,
});

export const findBorderoTipoStatusAction = () => ({
  type: FIND_BORDERO_TIPO_STATUS,
});

export const findTiposLogProcessamentoBorderoAction = () => ({
  type: FIND_TIPOS_LOG_PROCESSAMENTO_BORDERO,
});

export const findTipoPrazoExpiracaoPixAction = () => ({
  type: FIND_TIPO_PRAZO_EXPIRACAO_PIX,
});

export const findPastasFaseAction = (idFase, publico) => ({
  type: FIND_PASTAS_FASE,
  idFase,
  publico,
});

export const findNegociadoresAction = () => ({
  type: FIND_NEGOCIADORES,
});

export const findTiposAgendamentosAction = () => ({
  type: FIND_TIPOS_AGENDAMENTOS,
});

export const findUsuariosNegociadoresAction = () => ({
  type: FIND_USUARIOS_NEGOCIADORES_ACTION,
});

export const findStatusBorderoPesquisaAction = () => ({
  type: FIND_STATUS_BORDERO_IMPORTACAO,
});

export const findTipoTextoPadraoAction = () => ({
  type: FIND_TIPO_TEXTO_PADRAO,
});

export const findMotivosBaixaAction = () => ({
  type: FIND_MOTIVOS_BAIXA,
});

export const findAutoresAcompanhamentos = idProcesso => ({
  type: FIND_AUTORES_ACOMPANHAMENTO,
  idProcesso,
});

export const findTiposAcompanhamentoProcesso = idsTipoAcompanhamento => ({
  type: FIND_TIPOS_ACOMPANHAMENTO_PROCESSO,
  idsTipoAcompanhamento,
});

export const findTipoTituloAction = () => ({
  type: FIND_TIPO_TITULO,
});
export const findAllTiposCalendarioAction = () => ({
  type: FIND_ALL_TIPOS_CALENDARIO,
});

export const findAllResponsaveisConsultaDinamicaAction = () => ({
  type: FIND_ALL_RESPONSAVEIS_CONSULTA_DINAMICA,
});

export const findAllUsuariosComPermissaoConsultaDinamicaAction = idConsultaDinamica => ({
  type: FIND_ALL_USUARIOS_COM_PERMISSAO_CONSULTA_DINAMICA,
  idConsultaDinamica,
});

export const findAllTiposPrestacaoAction = () => ({
  type: FIND_ALL_TIPOS_PRESTACAO,
});

export const findAllTiposLiquidacaoPrestacaoAction = () => ({
  type: FIND_ALL_TIPOS_LIQUIDACAO_PRESTACAO,
});

export const findGeradoresRelatoriosTipoPrestacaoAction = () => ({
  type: FIND_GERADORES_RELATORIOS_TIPO_PRESTACAO,
});

export const findAllTiposChavePixAction = () => ({
  type: FIND_ALL_TIPOS_CHAVE_PIX,
});

export const findTiposDeLancamentosAction = () => ({
  type: FIND_TIPOS_LANCAMENTOS_ACTION,
});

export const findResponsaveisAction = () => ({
  type: FIND_RESPONSAVEIS_ACTION,
});

export const findTiposNotasFiscaisAction = () => ({
  type: FIND_TIPOS_NOTAS_FISCAIS_ACTION,
});

export const findPrefeiturasNotaFiscalAction = () => ({
  type: FIND_PREFEITURAS_NOTA_FISCAL_ACTION,
});

export const findTiposFormaPagamentoAction = () => ({
  type: FIND_TIPO_FORMA_PAGAMENTO_ACTION,
});

export const findTiposIntervaloPagamentoAction = () => ({
  type: FIND_TIPOS_INTERVALO_PAGAMENTO_ACTION,
});

export const findTiposFormasPagamentoAction = () => ({
  type: FIND_TIPOS_FORMAS_PAGAMENTO,
});

export const findContasBancariasAction = () => ({
  type: FIND_CONTAS_BANCARIAS,
});

export const findRegraComissaoComercial = () => ({
  type: FIND_TIPO_REGRA_COMISSIONAMENTO,
});

export const findUsuariosPermitemSimulacaoAction = () => ({
  type: FIND_USUARIOS_PERMITEM_SIMULACAO,
});

export const findMotivosQuebraAcordoListAction = () => ({
  type: FIND_MOTIVOS_QUEBRA_ACORDO_LIST_ACTION,
});

export const findNegociadoresExternosAtivosAction = idFase => ({
  type: FIND_NEGOCIADORES_EXTERNOS_ATIVOS_PARA_FASE,
  idFase,
});

export const findMotivosExclusaoTituloAction = () => ({
  type: FIND_MOTIVOS_EXCLUSAO_TITULO,
});

export const findNegociadoresValidosAction = idProcesso => ({
  type: FIND_NEGOCIADORES_VALIDOS,
  idProcesso,
});

export const findTiposTitulosAgendamentosAction = () => ({
  type: FIND_TIPOS_TITULOS_AGENDAMENTOS,
});

function* findStatusBorderoPesquisaHandler() {
  yield put(setLoadingAction('statusBorderoPesquisa', true));
  try {
    const response = yield getStatusBorderoPesquisa();
    const selectorData = yield buildStatusBorderoPesquisa(response.data);
    yield put(setSelectorDataAction('statusBorderoPesquisa', selectorData));
  } catch {
    yield put(setSelectorDataAction('statusBorderoPesquisa', []));
  } finally {
    yield put(setLoadingAction('statusBorderoPesquisa', false));
  }
}

function* findCategoriasHandler() {
  yield put(setLoadingAction('categorias', true));
  try {
    const response = yield getCategorias();
    const selectorData = yield buildCategoriasSelector(response.data);
    yield put(setSelectorDataAction('categorias', selectorData));
  } catch {
    yield put(setSelectorDataAction('categorias', []));
  } finally {
    yield put(setLoadingAction('categorias', false));
  }
}

function* findEstadosHandler() {
  yield put(setLoadingAction('estados', true));
  try {
    const response = yield getEstados();
    const selectorData = yield buildSelectorFromEstadosResponse(response.data);
    yield put(setSelectorDataAction('estados', selectorData));
  } catch {
    yield put(setSelectorDataAction('estados', []));
  } finally {
    yield put(setLoadingAction('estados', false));
  }
}

function* findTiposTelefoneHandler() {
  yield put(setLoadingAction('tiposTelefone', true));
  try {
    const response = yield getTipoTelefone();
    const selectorData = yield buildSelectorFromTipoTelefoneResponse(response.data);
    yield put(setSelectorDataAction('tiposTelefone', selectorData));
  } catch {
    yield put(setSelectorDataAction('tiposTelefone', []));
  } finally {
    yield put(setLoadingAction('tiposTelefone', false));
  }
}

function* findTiposTelefoneClientesHandler() {
  yield put(setLoadingAction('tiposTelefone', true));
  try {
    const response = yield getTipoTelefoneParaCliente();
    const selectorData = yield buildSelectorFromTipoTelefoneResponse(response.data);
    yield put(setSelectorDataAction('tiposTelefone', selectorData));
  } catch {
    yield put(setSelectorDataAction('tiposTelefone', []));
  } finally {
    yield put(setLoadingAction('tiposTelefone', false));
  }
}

function* findTiposTituloHandler() {
  yield put(setLoadingAction('tiposTitulo', true));
  try {
    const response = yield findTiposTitulo();
    const selectorData = yield buildTiposTituloSelector(response.data);
    yield put(setSelectorDataAction('tiposTitulo', selectorData));
  } catch {
    yield put(setSelectorDataAction('tiposTitulo', []));
  } finally {
    yield put(setLoadingAction('tiposTitulo', false));
  }
}

function* findUsuariosHandler(actions) {
  yield put(setLoadingAction('usuarios', true));
  try {
    let response;
    if (actions.tipoUsuario) {
      response = yield getShortUsuarios(actions.tipoUsuario);
    } else {
      response = yield getShortUsuarios();
    }
    const selectorData = yield buildUsuarios(response.data);
    yield put(setSelectorDataAction('usuarios', selectorData));
  } catch {
    yield put(setSelectorDataAction('usuarios', []));
  } finally {
    yield put(setLoadingAction('usuarios', false));
  }
}

function* findUsuariosTelemarketingHandler(actions) {
  yield put(setLoadingAction('usuariosTelemarketing', true));
  try {
    const response = yield getShortUsuarios(actions.tipoUsuario);
    const selectorData = yield buildUsuarios(response.data);
    yield put(setSelectorDataAction('usuariosTelemarketing', selectorData));
  } catch {
    yield put(setSelectorDataAction('usuariosTelemarketing', []));
  } finally {
    yield put(setLoadingAction('usuariosTelemarketing', false));
  }
}

function* findCentrosCustoHandler() {
  yield put(setLoadingAction('centrosCusto', true));
  try {
    const response = yield getCentroCusto();
    const selectorData = yield buildCentrosCusto(response.data);
    yield put(setSelectorDataAction('centrosCusto', selectorData));
  } catch {
    yield put(setSelectorDataAction('centrosCusto', []));
  } finally {
    yield put(setLoadingAction('centrosCusto', false));
  }
}

function* findTipoUsuarioHandler() {
  yield put(setLoadingAction('tipoUsuario', true));
  try {
    const response = yield call(getTiposUsuario);
    const selectorData = yield buildTipoUsuario(response.data);
    yield put(setSelectorDataAction('tipoUsuario', selectorData));
  } catch {
    yield put(setSelectorDataAction('tipoUsuario', []));
  } finally {
    yield put(setLoadingAction('tipoUsuario', false));
  }
}

function* findBancosHandler() {
  yield put(setLoadingAction('bancos', true));
  try {
    const response = yield getBancos();
    const selectorData = yield buildBancos(response.data);
    yield put(setSelectorDataAction('bancos', selectorData));
  } catch {
    yield put(setSelectorDataAction('bancos', []));
  } finally {
    yield put(setLoadingAction('bancos', false));
  }
}

function* findTiposContaHandler() {
  yield put(setLoadingAction('tiposConta', true));
  try {
    const response = yield getBancoTipoConta();
    const selectorData = yield buildTiposConta(response.data);
    yield put(setSelectorDataAction('tiposConta', selectorData));
  } catch {
    yield put(setSelectorDataAction('tiposConta', []));
  } finally {
    yield put(setLoadingAction('tiposConta', false));
  }
}

function* findPerfisHandler() {
  yield put(setLoadingAction('perfis', true));
  try {
    const response = yield getPerfiesUsuario();
    const selectorData = yield buildPerfies(response.data);
    yield put(setSelectorDataAction('perfis', selectorData));
  } catch {
    yield put(setSelectorDataAction('perfis', []));
  } finally {
    yield put(setLoadingAction('perfis', false));
  }
}

function* findFiliaisHandler() {
  yield put(setLoadingAction('filiais', true));
  try {
    const response = yield call(getShortFiliais, '', 'A');
    const selectorData = yield buildFiliais(response.data);
    yield put(setSelectorDataAction('filiais', selectorData));
  } catch {
    yield put(setSelectorDataAction('filiais', []));
  } finally {
    yield put(setLoadingAction('filiais', false));
  }
}

function* findTipoClassificacaoClientesHandler() {
  yield put(setLoadingAction('tipoClassificacaoClientes', true));
  try {
    const response = yield call(getTipoClassificacaoClientes);
    const selectorData = yield buildTipoClassificacaoClientes(response.data);
    yield put(setSelectorDataAction('tipoClassificacaoClientes', selectorData));
  } catch {
    yield put(setSelectorDataAction('tipoClassificacaoClientes', []));
  } finally {
    yield put(setLoadingAction('tipoClassificacaoClientes', false));
  }
}

function* findRamosAtividadesHandler() {
  yield put(setLoadingAction('ramosAtividades', true));
  try {
    const { data } = yield getShortRamoAtividade();
    const selectorData = yield buildRamosAtividade(data);
    yield put(setSelectorDataAction('ramosAtividades', selectorData));
  } catch {
    yield put(setSelectorDataAction('ramosAtividades', []));
  } finally {
    yield put(setLoadingAction('ramosAtividades', false));
  }
}

function* findPerfisUsuarioClienteHandler() {
  yield put(setLoadingAction('perfisUsuarioCliente', true));
  try {
    const { data } = yield getPerfiesCliente();
    const selectorData = yield buildPerfisUsuarioCliente(data);
    yield put(setSelectorDataAction('perfisUsuarioCliente', selectorData));
  } catch {
    yield put(setSelectorDataAction('perfisUsuarioCliente', []));
  } finally {
    yield put(setLoadingAction('perfisUsuarioCliente', false));
  }
}

function* findTipoCalculoHonorarioHandler() {
  yield put(setLoadingAction('tipoCalculoHonorarioParametro', true));
  try {
    const { data } = yield getTipoCalculoHonorario();
    const selectorData = yield buildTipoCalculoHonorarioParametro(data);
    yield put(setSelectorDataAction('tipoCalculoHonorarioParametro', selectorData));
  } catch {
    yield put(setSelectorDataAction('tipoCalculoHonorarioParametro', []));
  } finally {
    yield put(setLoadingAction('tipoCalculoHonorarioParametro', false));
  }
}

function* findTipoCalculoComissaoHandler() {
  yield put(setLoadingAction('tipoCalculoComissaoParametro', true));
  try {
    const { data } = yield getTipoCalculoComissao();
    const selectorData = yield buildTipoCalculoComissaoParametro(data);
    yield put(setSelectorDataAction('tipoCalculoComissaoParametro', selectorData));
  } catch {
    yield put(setSelectorDataAction('tipoCalculoComissaoParametro', []));
  } finally {
    yield put(setLoadingAction('tipoCalculoComissaoParametro', false));
  }
}

function* findTipoHonorariosContratuaisHandler() {
  yield put(setLoadingAction('tiposHonorariosContratuais', true));
  try {
    const { data } = yield getTiposHonorariosContratuais();
    const selectorData = yield buildTiposHonorariosContratuaisParametro(data);
    yield put(setSelectorDataAction('tiposHonorariosContratuais', selectorData));
  } catch {
    yield put(setSelectorDataAction('tiposHonorariosContratuais', []));
  } finally {
    yield put(setLoadingAction('tiposHonorariosContratuais', false));
  }
}

function* findTipoDescontoHandler() {
  yield put(setLoadingAction('tipoDescontoCliente', true));
  try {
    const { data } = yield getTipoDescontoCliente();
    const selectorData = yield buildTipoDesconto(data);
    yield put(setSelectorDataAction('tipoDescontoCliente', selectorData));
  } catch {
    yield put(setSelectorDataAction('tipoDescontoCliente', []));
  } finally {
    yield put(setLoadingAction('tipoDescontoCliente', false));
  }
}

function* findAllFasesHandler(actions) {
  yield put(setLoadingAction('fases', true));
  try {
    const { permiteReceberProcesso } = actions;
    const { data } = yield call(findAllFases, permiteReceberProcesso);
    const selectorData = yield buildFases(data);
    yield put(setSelectorDataAction('fases', selectorData));
  } catch {
    yield put(setSelectorDataAction('fases', []));
  } finally {
    yield put(setLoadingAction('fases', false));
  }
}

function* findFasesWhatsappHandler(actions) {
  yield put(setLoadingAction('fases', true));
  try {
    const { permiteReceberProcesso } = actions;
    const { data } = yield call(findAllFasessWhatsapp, permiteReceberProcesso);
    const selectorData = yield buildFases(data);
    yield put(setSelectorDataAction('fases', selectorData));
  } catch {
    yield put(setSelectorDataAction('fases', []));
  } finally {
    yield put(setLoadingAction('fases', false));
  }
}

function* findRequisicoesHandler() {
  yield put(setLoadingAction('requisicoes', true));
  try {
    const { data } = yield call(getAllRequisicoesSimplificadas);
    const selectorData = yield buildRequisicoes(data);
    yield put(setSelectorDataAction('requisicoes', selectorData));
  } catch {
    yield put(setSelectorDataAction('requisicoes', []));
  } finally {
    yield put(setLoadingAction('requisicoes', false));
  }
}

function* findRegraCalculoRepasseHandler() {
  yield put(setLoadingAction('regraCalculoRepasseParametro', true));
  try {
    const { data } = yield getRegraCalculoRepasse();
    const selectorData = yield buildRegraCalculoRepasseParametro(data);
    yield put(setSelectorDataAction('regraCalculoRepasseParametro', selectorData));
  } catch {
    yield put(setSelectorDataAction('regraCalculoRepasseParametro', []));
  } finally {
    yield put(setLoadingAction('regraCalculoRepasseParametro', false));
  }
}

function* findTipoUsuarioComercialPrincipalClienteHandler(actions) {
  yield put(setLoadingAction('tipoUsuarioComercialPrincipal', true));
  try {
    const { idCliente, tipoUsuarioComercial } = actions;

    const { data } = yield call(getTiposUsuariosCliente, idCliente, tipoUsuarioComercial);
    const selectorData = yield buildUsuarios(data);
    yield put(setSelectorDataAction('tipoUsuarioComercialPrincipal', selectorData));
  } catch {
    yield put(setSelectorDataAction('tipoUsuarioComercialPrincipal', []));
  } finally {
    yield put(setLoadingAction('tipoUsuarioComercialPrincipal', false));
  }
}

function* findTipoUsuarioComercialTelemarketingClienteHandler(actions) {
  yield put(setLoadingAction('tipoUsuarioComercialTelemarketing', true));
  try {
    const { idCliente, tipoUsuarioComercial } = actions;

    const { data } = yield call(getTiposUsuariosCliente, idCliente, tipoUsuarioComercial);
    const selectorData = yield buildUsuarios(data);
    yield put(setSelectorDataAction('tipoUsuarioComercialTelemarketing', selectorData));
  } catch {
    yield put(setSelectorDataAction('tipoUsuarioComercialTelemarketing', []));
  } finally {
    yield put(setLoadingAction('tipoUsuarioComercialTelemarketing', false));
  }
}

function* findTipoAcordoSeguroHandler() {
  yield put(setLoadingAction('tipoAcordoSeguro', true));
  try {
    const { data } = yield call(getTipoAcordoSeguro);
    const selectorData = yield buildTipoAcordoSeguro(data);
    yield put(setSelectorDataAction('tipoAcordoSeguro', selectorData));
  } catch {
    yield put(setSelectorDataAction('tipoAcordoSeguro', []));
  } finally {
    yield put(setLoadingAction('tipoAcordoSeguro', false));
  }
}

function* findIntervaloPagamentoHandler() {
  yield put(setLoadingAction('intervaloPagamento', true));
  try {
    const { data } = yield call(getIntervaloPagamento);
    const selectorData = yield buildIntervaloPagamento(data);
    yield put(setSelectorDataAction('intervaloPagamento', selectorData));
  } catch {
    yield put(setSelectorDataAction('intervaloPagamento', []));
  } finally {
    yield put(setLoadingAction('intervaloPagamento', false));
  }
}

function* findModeloNotificacaoEmailHandler() {
  yield put(setLoadingAction('modeloNotificacaoEmail', true));
  try {
    const { data } = yield call(getModeloNotificacaoEmail);
    const selectorData = yield buildModeloNotificacaoEmail(data);
    yield put(setSelectorDataAction('modeloNotificacaoEmail', selectorData));
  } catch {
    yield put(setSelectorDataAction('modeloNotificacaoEmail', []));
  } finally {
    yield put(setLoadingAction('modeloNotificacaoEmail', false));
  }
}

function* findTipoComissaoBorderoHandler() {
  yield put(setLoadingAction('tipoComissaoBordero', true));
  try {
    const { data } = yield call(getTipoComissaoBordero);
    const selectorData = yield buildTipoComissaoBordero(data);
    yield put(setSelectorDataAction('tipoComissaoBordero', selectorData));
  } catch {
    yield put(setSelectorDataAction('tipoComissaoBordero', []));
  } finally {
    yield put(setLoadingAction('tipoComissaoBordero', false));
  }
}

function* findBorderoTipoStatusHandler() {
  yield put(setLoadingAction('borderoTipoStatus', true));
  try {
    const response = yield call(getBorderoTipoStatus);
    const selectorData = yield buildBorderoTipoStatus(response.data);
    yield put(setSelectorDataAction('borderoTipoStatus', selectorData));
  } catch {
    yield put(setSelectorDataAction('borderoTipoStatus', []));
  } finally {
    yield put(setLoadingAction('borderoTipoStatus', false));
  }
}

function* findTiposLogProcessamentoBorderoHandler() {
  yield put(setLoadingAction('tiposLogProcessamentoBordero', true));
  try {
    const { data } = yield call(getTiposLogProcessamentoBordero);
    const selectorData = yield buildTiposLogProcessamentoBordero(data);
    yield put(setSelectorDataAction('tiposLogProcessamentoBordero', selectorData));
  } catch {
    yield put(setSelectorDataAction('tiposLogProcessamentoBordero', []));
  } finally {
    yield put(setLoadingAction('tiposLogProcessamentoBordero', false));
  }
}

function* findTipoPrazoExpiracaoPixHandler() {
  yield put(setLoadingAction('tipoPrazoExpiracaoPix', true));
  try {
    const { data } = yield call(getTipoPrazoExpiracaoPix);
    const selectorData = yield buildTipoPrazoExpiracaoPix(data);
    yield put(setSelectorDataAction('tipoPrazoExpiracaoPix', selectorData));
  } catch {
    yield put(setSelectorDataAction('tipoPrazoExpiracaoPix', []));
  } finally {
    yield put(setLoadingAction('tipoPrazoExpiracaoPix', false));
  }
}

function* findPastasHandler(actions) {
  yield put(setLoadingAction('pastasDaFase', true));
  const { idFase, publico } = actions;
  if (!idFase) {
    yield put(setSelectorDataAction('pastasDaFase', []));
    return;
  }

  try {
    const { data } = yield call(findPastas, idFase, publico);
    const selectorData = yield buildFasesPastas(data);
    yield put(setSelectorDataAction('pastasDaFase', selectorData));
  } catch {
    yield put(setSelectorDataAction('pastasDaFase', []));
  } finally {
    yield put(setLoadingAction('pastasDaFase', false));
  }
}

function* findNegociadoresHandler() {
  yield put(setLoadingAction('negociadores', true));
  try {
    const { data } = yield call(getTiposUsuariosNegociador);
    const selectorData = yield buildUsuarios(data);
    yield put(setSelectorDataAction('negociadores', selectorData));
  } catch {
    yield put(setSelectorDataAction('negociadores', []));
  } finally {
    yield put(setLoadingAction('negociadores', false));
  }
}

function* findTiposAgendamentosHandler() {
  yield put(setLoadingAction('tiposAgendamentos', true));
  try {
    const { data } = yield call(getTiposAgendamentoProcessoService);
    const selectorData = yield buildDefaultSelector(data);
    yield put(setSelectorDataAction('tiposAgendamentos', selectorData));
  } catch {
    yield put(setSelectorDataAction('tiposAgendamentos', []));
  } finally {
    yield put(setLoadingAction('tiposAgendamentos', false));
  }
}

function* findUsuariosNegociadoresHandler() {
  yield put(setLoadingAction('usuariosNegociadores', true));
  try {
    const { data } = yield call(getUsuariosNegociadoresProcessoService);
    const selectorData = yield buildDefaultSelector(data);
    yield put(setSelectorDataAction('usuariosNegociadores', selectorData));
  } catch {
    yield put(setSelectorDataAction('usuariosNegociadores', []));
  } finally {
    yield put(setLoadingAction('usuariosNegociadores', false));
  }
}

function* findTipoTextoPadraoHandler() {
  yield put(setLoadingAction('tipoTextoPadrao', true));
  try {
    const { data } = yield getTipoTextoPadrao();
    const selectorData = yield buildTipoTextoPadrao(data);
    yield put(setSelectorDataAction('tipoTextoPadrao', selectorData));
  } catch {
    yield put(setSelectorDataAction('tipoTextoPadrao', []));
  } finally {
    yield put(setLoadingAction('tipoTextoPadrao', false));
  }
}

function* findMotivosBaixaHandler() {
  yield put(setLoadingAction('motivosBaixa', true));
  try {
    const { data } = yield call(buscarMotivoBaixa);
    const selectorData = yield buildMotivosBaixa(data);
    yield put(setSelectorDataAction('motivosBaixa', selectorData));
  } catch {
    yield put(setSelectorDataAction('motivosBaixa', []));
  } finally {
    yield put(setLoadingAction('motivosBaixa', false));
  }
}

function* findAutoresAcompanhamentosHandler(actions) {
  yield put(setLoadingAction('autoresAcompanhamentos', true));
  try {
    const { data } = yield call(
      findAutoresAcompanhamentosService,
      actions.idProcesso,
    );
    const selectorData = yield buildDefaultSelector(data);
    yield put(setSelectorDataAction('autoresAcompanhamentos', selectorData));
  } catch {
    yield put(setSelectorDataAction('autoresAcompanhamentos', []));
  } finally {
    yield put(setLoadingAction('autoresAcompanhamentos', false));
  }
}

function* findTiposAcompanhamentoProcessoHandler(actions) {
  yield put(setLoadingAction('tiposAcompanhamentoProcesso', true));
  try {
    const { data } = yield call(findTiposAcompanhamentosProcessoService, actions.idsTipoAcompanhamento);
    const selectorData = yield buildDefaultSelector(data);
    yield put(setSelectorDataAction('tiposAcompanhamentoProcesso', selectorData));
  } catch {
    yield put(setSelectorDataAction('tiposAcompanhamentoProcesso', []));
  } finally {
    yield put(setLoadingAction('tiposAcompanhamentoProcesso', false));
  }
}

function* findAllResponsaveisConsultaDinamicaHandler() {
  yield put(setLoadingAction('usuariosConsultaDinamica', true));
  try {
    const { data } = yield call(ConsultaDinamicaService.findAllResponsaveis);
    const selectorData = yield buildUsuarios(data);
    yield put(setSelectorDataAction('usuariosConsultaDinamica', selectorData));
  } catch {
    yield put(setSelectorDataAction('usuariosConsultaDinamica', []));
  } finally {
    yield put(setLoadingAction('usuariosConsultaDinamica', false));
  }
}

function* findTipoTituloHandler() {
  yield put(setLoadingAction('tipoTitulo', true));
  try {
    const { data } = yield getTipoTitulo();
    const selectorData = yield buildTipoTitulo(data);
    yield put(setSelectorDataAction('tipoTitulo', selectorData));
  } catch {
    yield put(setSelectorDataAction('tipoTitulo', []));
  } finally {
    yield put(setLoadingAction('tipoTitulo', false));
  }
}
function* findAllTiposCalendario() {
  yield put(setLoadingAction('tiposCalendario', true));
  try {
    const { data } = yield call(buscarTiposCalendarioService);
    const selectorData = yield buildDefaultSelector(data);
    yield put(setSelectorDataAction('tiposCalendario', selectorData));
  } catch {
    yield put(setSelectorDataAction('tiposCalendario', []));
  } finally {
    yield put(setLoadingAction('tiposCalendario', false));
  }
}

function* findAllUsuarioComPermissaoConsultaDinamicaHandler(actions) {
  yield put(setLoadingAction('usuariosPermissaoConsultaDinamica', true));
  try {
    const { data } = yield call(ConsultaDinamicaService.findAllUsuarioComPermissaoConsultaDinamica, actions.idConsultaDinamica);
    const selectorData = yield buildUsuarios(data);
    yield put(setSelectorDataAction('usuariosPermissaoConsultaDinamica', selectorData));
  } catch (e) {
    yield put(setSelectorDataAction('usuariosPermissaoConsultaDinamica', []));
  } finally {
    yield put(setLoadingAction('usuariosPermissaoConsultaDinamica', false));
  }
}

function* findAllTiposPrestacaoHandler() {
  yield put(setLoadingAction('tiposPrestacao', true));
  try {
    const { data } = yield call(findAllTiposPrestacao);
    const selectorData = yield buildTiposPrestacao(data);
    yield put(setSelectorDataAction('tiposPrestacao', selectorData));
  } catch (e) {
    yield put(setSelectorDataAction('tiposPrestacao', []));
  } finally {
    yield put(setLoadingAction('tiposPrestacao', false));
  }
}

function* findAllTiposLiquidacaoPrestacaoHandler() {
  yield put(setLoadingAction('tiposLiquidacaoPrestacao', true));
  try {
    const { data } = yield call(findAllTiposLiquidacaoPrestacao);
    const selectorData = yield buildTiposLiquidacaoPrestacao(data);
    yield put(setSelectorDataAction('tiposLiquidacaoPrestacao', selectorData));
  } catch (e) {
    yield put(setSelectorDataAction('tiposLiquidacaoPrestacao', []));
  } finally {
    yield put(setLoadingAction('tiposLiquidacaoPrestacao', false));
  }
}

function* findGeradoresRelatoriosTipoPrestacaoHandler() {
  yield put(setLoadingAction('geradoresRelatorios', true));
  try {
    const { data } = yield call(findGeradoresRelatoriosTipoPrestacao);
    const selectorData = yield buildGeradoresRelatorios(data);
    yield put(setSelectorDataAction('geradoresRelatorios', selectorData));
  } catch (e) {
    yield put(setSelectorDataAction('geradoresRelatorios', []));
  } finally {
    yield put(setLoadingAction('geradoresRelatorios', false));
  }
}

function* findAllTiposChavePixHandler() {
  yield put(setLoadingAction('tiposChavesPix', true));
  try {
    const { data } = yield call(findAllTiposChavePix);
    const selectorData = yield buildTiposChavesPix(data);
    yield put(setSelectorDataAction('tiposChavesPix', selectorData));
  } catch (e) {
    yield put(setSelectorDataAction('tiposChavesPix', []));
  } finally {
    yield put(setLoadingAction('tiposChavesPix', false));
  }
}

function* findTiposDeLancamentosHandler() {
  yield put(setLoadingAction('tiposLancamentosItems', true));
  try {
    const response = yield call(findAllTiposDeLancamentos);
    yield put(setSelectorDataAction('tiposLancamentosItems', buildTiposLancamentos(response.data)));
  } catch (e) {
    // Nenhum tratamento definido, então configurar um array vazio...
    yield put(setSelectorDataAction('tiposLancamentosItems', []));
    toastUnmappedException(e, TIPO_LANCAMENTOS_ERROR);
  } finally {
    yield put(setLoadingAction('tiposLancamentosItems', false));
  }
}

function* findResponsaveisHandler() {
  yield put(setLoadingAction('responsaveisItems', true));
  try {
    const response = yield call(findAllResponsaveis);
    yield put(setSelectorDataAction('responsaveisItems', buildResponsaveisCC(response.data)));
  } catch (e) {
    // Nenhum tratamento definido, então configurar um array vazio...
    yield put(setSelectorDataAction('responsaveisItems', []));
    toastUnmappedException(e, RESPONSAVEIS_ERROR);
  } finally {
    yield put(setLoadingAction('responsaveisItems', false));
  }
}

function* findAllTiposNotasFiscaisHandler() {
  yield put(setLoadingAction('tiposNotasFiscais', true));
  try {
    const { data } = yield call(findAllTiposNotasFiscais);
    const selectorData = yield buildTiposNotasFiscais(data);
    yield put(setSelectorDataAction('tiposNotasFiscais', selectorData));
  } catch (e) {
    yield put(setSelectorDataAction('tiposNotasFiscais', []));
  } finally {
    yield put(setLoadingAction('tiposNotasFiscais', false));
  }
}

function* findAllPrefeiturasNotaFiscalHandler() {
  yield put(setLoadingAction('prefeiturasNotaFiscal', true));
  try {
    const { data } = yield call(findAllPrefeiturasNotaFiscal);
    const selectorData = yield buildPrefeiturasNotaFiscal(data);
    yield put(setSelectorDataAction('prefeiturasNotaFiscal', selectorData));
  } catch (e) {
    yield put(setSelectorDataAction('prefeiturasNotaFiscal', []));
  } finally {
    yield put(setLoadingAction('prefeiturasNotaFiscal', false));
  }
}

function* findTipoFormaPagamentoHandler() {
  yield put(setLoadingAction('tiposFormaPagamento', true));
  try {
    const { data } = yield call(getTiposFormaPagamentoService);
    const selectorData = yield buildTipoFormaPagamento(data);
    yield put(setSelectorDataAction('tiposFormaPagamento', selectorData));
  } catch {
    yield put(setSelectorDataAction('tiposFormaPagamento', []));
  } finally {
    yield put(setLoadingAction('tiposFormaPagamento', false));
  }
}

function* findTiposIntervaloPagamentoHandler() {
  yield put(setLoadingAction('tiposIntervaloPagamento', true));
  try {
    const { data } = yield call(getTiposIntervaloPagamentoService);
    const selectorData = yield buildTipoIntervaloPagamento(data);
    yield put(setSelectorDataAction('tiposIntervaloPagamento', selectorData));
  } catch {
    yield put(setSelectorDataAction('tiposIntervaloPagamento', []));
  } finally {
    yield put(setLoadingAction('tiposIntervaloPagamento', false));
  }
}

function* findTiposFormasPagamentoHandler() {
  yield put(setLoadingAction('tiposFormasPagamentoRecebimento', true));
  try {
    const response = yield RecebimentoClienteService.getTiposFormasPagamento();
    const selectorData = yield buildTiposFormasPagamentoRecebimento(response.data);
    yield put(setSelectorDataAction('tiposFormasPagamentoRecebimento', selectorData));
  } catch {
    yield put(setSelectorDataAction('tiposFormasPagamentoRecebimento', []));
  } finally {
    yield put(setLoadingAction('tiposFormasPagamentoRecebimento', false));
  }
}

function* findContasBancariasHandler() {
  yield put(setLoadingAction('contaBancaria', true));
  try {
    const response = yield RecebimentoClienteService.getContasBancarias();
    const selectorData = yield buildContasBancarias(response.data);
    yield put(setSelectorDataAction('contaBancaria', selectorData));
  } catch {
    yield put(setSelectorDataAction('contaBancaria', []));
  } finally {
    yield put(setLoadingAction('contaBancaria', false));
  }
}

function* findRegraComissionamentoHandler() {
  yield put(setLoadingAction('tipoComissao', true));
  try {
    const response = yield getTipoRegraComissionamento();
    const selectorData = yield buildRegraComissionamento(response.data);
    yield put(setSelectorDataAction('tipoComissao', selectorData));
  } catch {
    yield put(setSelectorDataAction('tipoComissao', []));
  } finally {
    yield put(setLoadingAction('tipoComissao', false));
  }
}

function* findUsuariosPermitemSimulacaoHandler() {
  yield put(setLoadingAction('usuariosPermitemSimulacao', true));
  try {
    const response = yield findUsuariosPermitemSimulacao();
    const selectorData = yield buildUsuariosPermitemSimulacao(response.data);
    yield put(setSelectorDataAction('usuariosPermitemSimulacao', selectorData));
  } catch {
    yield put(setSelectorDataAction('usuariosPermitemSimulacao', []));
  } finally {
    yield put(setLoadingAction('usuariosPermitemSimulacao', false));
  }
}

function* findMotivosQuebraAcordoListHandler() {
  yield put(setLoadingAction('motivosQuebraAcordo', true));
  try {
    const response = yield motivosQuebraAcordo();
    const selectorData = yield buildMotivosQuebraAcordo(response.data);
    yield put(setSelectorDataAction('motivosQuebraAcordo', selectorData));
  } catch {
    yield put(setSelectorDataAction('motivosQuebraAcordo', []));
  } finally {
    yield put(setLoadingAction('motivosQuebraAcordo', false));
  }
}

function* findNegociadoresExternosAtivosFaseHandler({ idFase }) {
  yield put(setLoadingAction('negociadoresExternosAtivosFase', true));
  try {
    const response = yield buscarNegociadoresDaFaseService(idFase);
    const selectorData = yield buildNegociadoresExternosAtivosFase(response.data);
    yield put(setSelectorDataAction('negociadoresExternosAtivosFase', selectorData));
  } catch {
    yield put(setSelectorDataAction('negociadoresExternosAtivosFase', []));
  } finally {
    yield put(setLoadingAction('negociadoresExternosAtivosFase', false));
  }
}

function* findMotivosExclusaoTituloHandler() {
  yield put(setLoadingAction('motivosExclusaoTitulo', true));
  try {
    const response = yield buscarMotivosExclusaoTitulo();
    const selectorData = yield buildMotivosExclusaoTitulo(response.data);
    yield put(setSelectorDataAction('motivosExclusaoTitulo', selectorData));
  } catch {
    yield put(setSelectorDataAction('motivosExclusaoTitulo', []));
  } finally {
    yield put(setLoadingAction('motivosExclusaoTitulo', false));
  }
}

function* findNegociadoresValidosHandler({ idProcesso }) {
  yield put(setLoadingAction('negociadoresValidos', true));

  try {
    const response = yield buscarNegociadoresHabilitadosProcesso(idProcesso);
    const selectorData = yield buildNegociadoresHabilitadosProcesso(response.data);
    yield put(setSelectorDataAction('negociadoresValidos', selectorData));
  } catch {
    yield put(setSelectorDataAction('negociadoresValidos', []));
  } finally {
    yield put(setLoadingAction('negociadoresValidos', false));
  }
}

function* findTiposTituloAgendamentosHandler() {
  yield put(setLoadingAction('tiposTitulosAgendamentos', true));
  try {
    const { data } = yield call(getTiposTituloAgendamentoProcessoService);
    const selectorData = yield buildTiposTitulosAgendamentos(data);
    yield put(setSelectorDataAction('tiposTitulosAgendamentos', selectorData));
  } catch {
    yield put(setSelectorDataAction('tiposTitulosAgendamentos', []));
  } finally {
    yield put(setLoadingAction('tiposTitulosAgendamentos', false));
  }
}

export default function* watchLoadSelector() {
  yield takeLatest(FIND_CATEGORIAS, findCategoriasHandler);
  yield takeLatest(FIND_ESTADOS, findEstadosHandler);
  yield takeLatest(FIND_TIPOS_TELEFONE, findTiposTelefoneHandler);
  yield takeLatest(FIND_TIPOS_TELEFONE_CLIENTES, findTiposTelefoneClientesHandler);
  yield takeLatest(FIND_TIPOS_TITULO, findTiposTituloHandler);
  yield takeLatest(FIND_USUARIOS, findUsuariosHandler);
  yield takeLatest(FIND_CENTRO_CUSTO, findCentrosCustoHandler);
  yield takeLatest(FIND_TIPO_USUARIO, findTipoUsuarioHandler);
  yield takeLatest(FIND_TIPOS_CONTA, findTiposContaHandler);
  yield takeLatest(FIND_PERFIS, findPerfisHandler);
  yield takeLatest(FIND_BANCOS, findBancosHandler);
  yield takeLatest(FIND_FILIAIS, findFiliaisHandler);
  yield takeLatest(FIND_TIPO_CLASSIFICACAO_CLIENTES_ACTION, findTipoClassificacaoClientesHandler);
  yield takeLatest(FIND_RAMOS_ATIVIDADES, findRamosAtividadesHandler);
  yield takeLatest(FIND_PERFIS_USUARIOS_CLIENTE, findPerfisUsuarioClienteHandler);
  yield takeLatest(FIND_TIPO_CALCULO_HONORARIO_PARAMETRO, findTipoCalculoHonorarioHandler);
  yield takeLatest(FIND_TIPO_CALCULO_COMISSAO_PARAMETRO, findTipoCalculoComissaoHandler);
  yield takeLatest(FIND_TIPO_HONORARIOS_CONTRATUAIS_ACTION, findTipoHonorariosContratuaisHandler);
  yield takeLatest(FIND_TIPO_DESCONTO, findTipoDescontoHandler);
  yield takeLatest(FIND_TIPO_FASES, findAllFasesHandler);
  yield takeLatest(FIND_TIPO_FASES_WHATSAPP, findFasesWhatsappHandler);
  yield takeLatest(FIND_REQUISICOES, findRequisicoesHandler);
  yield takeLatest(FIND_REGRA_CALCULO_REPASSE_PARAMETRO, findRegraCalculoRepasseHandler);
  yield takeLatest(FIND_TIPO_USUARIO_COMERCIAL_PRINCIPAL, findTipoUsuarioComercialPrincipalClienteHandler);
  yield takeLatest(FIND_TIPO_USUARIO_COMERCIAL_TELEMARKETING, findTipoUsuarioComercialTelemarketingClienteHandler);
  yield takeLatest(FIND_TIPO_ACORDO_SEGURO, findTipoAcordoSeguroHandler);
  yield takeLatest(FIND_INTERVALO_ENTRE_PAGAMENTO, findIntervaloPagamentoHandler);
  yield takeLatest(FIND_MODELO_NOTIFICACAO_EMAIL, findModeloNotificacaoEmailHandler);
  yield takeLatest(FIND_TIPO_COMISSAO_BORDERO, findTipoComissaoBorderoHandler);
  yield takeLatest(FIND_USUARIOS_TELEMARKETING, findUsuariosTelemarketingHandler);
  yield takeLatest(FIND_BORDERO_TIPO_STATUS, findBorderoTipoStatusHandler);
  yield takeLatest(FIND_TIPOS_LOG_PROCESSAMENTO_BORDERO, findTiposLogProcessamentoBorderoHandler);
  yield takeLatest(FIND_TIPO_PRAZO_EXPIRACAO_PIX, findTipoPrazoExpiracaoPixHandler);
  yield takeLatest(FIND_PASTAS_FASE, findPastasHandler);
  yield takeLatest(FIND_NEGOCIADORES, findNegociadoresHandler);
  yield takeLatest(FIND_TIPOS_AGENDAMENTOS, findTiposAgendamentosHandler);
  yield takeLatest(FIND_USUARIOS_NEGOCIADORES_ACTION, findUsuariosNegociadoresHandler);
  yield takeLatest(FIND_STATUS_BORDERO_IMPORTACAO, findStatusBorderoPesquisaHandler);
  yield takeLatest(FIND_TIPO_TEXTO_PADRAO, findTipoTextoPadraoHandler);
  yield takeLatest(FIND_MOTIVOS_BAIXA, findMotivosBaixaHandler);
  yield takeLatest(FIND_AUTORES_ACOMPANHAMENTO, findAutoresAcompanhamentosHandler);
  yield takeLatest(FIND_TIPOS_ACOMPANHAMENTO_PROCESSO, findTiposAcompanhamentoProcessoHandler);
  yield takeLatest(FIND_TIPO_TITULO, findTipoTituloHandler);
  yield takeLatest(FIND_ALL_TIPOS_CALENDARIO, findAllTiposCalendario);
  yield takeLatest(FIND_ALL_RESPONSAVEIS_CONSULTA_DINAMICA, findAllResponsaveisConsultaDinamicaHandler);
  yield takeLatest(FIND_ALL_USUARIOS_COM_PERMISSAO_CONSULTA_DINAMICA, findAllUsuarioComPermissaoConsultaDinamicaHandler);
  yield takeLatest(FIND_ALL_TIPOS_PRESTACAO, findAllTiposPrestacaoHandler);
  yield takeLatest(FIND_ALL_TIPOS_LIQUIDACAO_PRESTACAO, findAllTiposLiquidacaoPrestacaoHandler);
  yield takeLatest(FIND_GERADORES_RELATORIOS_TIPO_PRESTACAO, findGeradoresRelatoriosTipoPrestacaoHandler);
  yield takeLatest(FIND_ALL_TIPOS_CHAVE_PIX, findAllTiposChavePixHandler);
  yield takeLatest(FIND_TIPOS_LANCAMENTOS_ACTION, findTiposDeLancamentosHandler);
  yield takeLatest(FIND_RESPONSAVEIS_ACTION, findResponsaveisHandler);
  yield takeLatest(FIND_TIPOS_NOTAS_FISCAIS_ACTION, findAllTiposNotasFiscaisHandler);
  yield takeLatest(FIND_PREFEITURAS_NOTA_FISCAL_ACTION, findAllPrefeiturasNotaFiscalHandler);
  yield takeLatest(FIND_TIPO_FORMA_PAGAMENTO_ACTION, findTipoFormaPagamentoHandler);
  yield takeLatest(FIND_TIPOS_INTERVALO_PAGAMENTO_ACTION, findTiposIntervaloPagamentoHandler);
  yield takeLatest(FIND_TIPOS_FORMAS_PAGAMENTO, findTiposFormasPagamentoHandler);
  yield takeLatest(FIND_CONTAS_BANCARIAS, findContasBancariasHandler);
  yield takeLatest(FIND_TIPO_REGRA_COMISSIONAMENTO, findRegraComissionamentoHandler);
  yield takeLatest(FIND_USUARIOS_PERMITEM_SIMULACAO, findUsuariosPermitemSimulacaoHandler);
  yield takeLatest(FIND_MOTIVOS_QUEBRA_ACORDO_LIST_ACTION, findMotivosQuebraAcordoListHandler);
  yield takeLatest(FIND_NEGOCIADORES_EXTERNOS_ATIVOS_PARA_FASE, findNegociadoresExternosAtivosFaseHandler);
  yield takeLatest(FIND_MOTIVOS_EXCLUSAO_TITULO, findMotivosExclusaoTituloHandler);
  yield takeLatest(FIND_NEGOCIADORES_VALIDOS, findNegociadoresValidosHandler);
  yield takeLatest(FIND_TIPOS_TITULOS_AGENDAMENTOS, findTiposTituloAgendamentosHandler);
}
