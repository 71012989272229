import React, { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import Grid24 from '../../../../components/UI/Grid24';
import DateField from '../../../../components/UI/Field/Date/DateField';
import { getRequiredLabel, toBrazilianDate } from '../../../../helpers';
import MoneyField from '../../../../components/UI/Field/Money/MoneyField';
import { InputField } from '../../../../components';
import { salvarCustasDadosJuridicos } from '../../../../services/core/processos/processoDadosJuridicosService';


function DialogNovoCustas({
  setOptionalDialogNovo,
  idProcesso,
  updatePage,
}) {
  const [data, setData] = useState(toBrazilianDate(new Date()));
  const [historico, setHistorico] = useState('');
  const [valor, setValor] = useState('');

  const disableConfirmarCustas = !data || !historico || !valor;

  const onClickConfirmarCustas = useCallback(async () => {
    try {
      await salvarCustasDadosJuridicos(data,
        historico,
        valor,
        idProcesso);
      setOptionalDialogNovo(false);
      updatePage();
      toast.success('Custa incluída com sucesso.');
    } catch {
      setOptionalDialogNovo(false);
      toast.error('Não foi possível salvar a custa. Tente novamente.', { style: { width: '392px' } });
    }
  }, [setOptionalDialogNovo, data, historico, valor, idProcesso, updatePage]);

  return (
    <>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} style={{ marginBottom: '15px' }}>
          <Grid24 item xs={24} sm={8}>
            <DateField
              isForm
              fullWidth
              label={getRequiredLabel('Data', true)}
              name="data"
              value={data}
              onChange={(e) => { setData(e.target.value); }}
            />
          </Grid24>
          <Grid24
            item
            xs={24}
            sm={8}
            style={{
              paddingRight: '8px',
            }}
          >
            <InputField
              isForm
              fullWidth
              autoComplete="off"
              label={getRequiredLabel('Histórico', true)}
              name="historico"
              value={historico}
              onChange={(e) => { setHistorico(e.target.value); }}
            />
          </Grid24>
          <Grid24 item xs={24} sm={8}>
            <MoneyField
              isForm
              fullWidth
              acceptEmptyValue
              label={getRequiredLabel('Valor', true)}
              name="valor"
              value={valor}
              onChange={(e) => { setValor(e.target.value); }}
            />
          </Grid24>
        </Grid24>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          onClick={() => { setOptionalDialogNovo(false); }}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickConfirmarCustas}
          disabled={disableConfirmarCustas}
        >
          Confirmar
        </Button>
      </div>

    </>
  );
}


export default DialogNovoCustas;
