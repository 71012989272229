import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { v4 as uuid } from 'uuid';

import { AccordionDetails } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import { toast } from 'react-toastify';
import { Accordion } from '../../components/UI/Inspecionar/Accordion/Accordion';
import { TabsUi } from '../../components/UI/TabsUi/TabsUI';
import LoadingUI from '../../components/UI/Loading/LoadingUI';
import TooltipUI from '../../components/UI/Tooltip/Tooltip';

import { actionProps, inspecionarProcessoPageController as controller } from './inspecaoProcessoController';
import { loadingInspecaoProcessoController as loadingController } from './loadingInspecaoProcessoController';


import { Acompanhamento } from './Acompanhamento/Acompanhamento';
import {
  Accordions,
  ActionButton,
  ActionButtonGroup,
  ActionContainer,
  ActionContentContainer,
  AdditionalInfoContainer,
  Container,
  FooterInspecionar,
  HeaderContainer,
  MainContainer,
} from './InspecionarProcessoStyles';
import { ResumoProcesso } from './Resumo/Resumo';
import { DadosDevedorProcesso } from './DadosDevedor/DadosDevedor';
import { DadosClienteProcesso } from './DadosCliente/DadosCliente';
import { TitulosProcesso } from './Titulos/Titulos';
import { FaseProcesso } from './Fase/Fase';

import { initializePageAction } from '../../store/theme.actions';
import { PreferenciasProcesso } from './PreferenciasProcesso/PreferenciasProcesso';
import SinalizacaoCores from './Sinalizacao/Cores/SinalizacaoCores';
import SinalizacaoAlertas from './Sinalizacao/Alertas/SinalizacaoAlertas';
import SinalizacaoIcones from './Sinalizacao/Icones/SinalizacaoIcones';
import { AntecedenteProcesso } from './AntecendentesProcesso';
import ButtonUI from '../../components/UI/Button/ButtonUI';
import { DocumentosProcesso } from './DocumentosProcesso/DocumentosProcesso';
import { ListaRecalculoProcesso } from '../../components/ListaRecalculoProcesso/ListaRecalculoProcesso';
import Acordos from './Acordos/Acordos';
import { buscarIdFaseAcordoSeguro, buscarIdFaseBaixa } from '../../services/core/fase/fase.services';
import { FichaFinanceira } from './FichaFinanceira/FichaFinaceira';
import { DadosJuridicos } from './DadosJuridicos/DadosJuridicos';


function InspecionarProcessoPageComponent() {
  const dispatch = useDispatch();
  const history = useHistory();

  const acompanhamentoRef = useRef();


  const [currentTabSelected, setCurrentTabSelected] = useState({ tabIndex: 0, processo: { id: null, key: uuid() } });
  const [idsProcessos, setIdsProcessos] = useState([]);
  const [additionalInfos, setAdditionalInfos] = useState({});


  const [fases, setFases] = useState([]);
  const [fasesIds, setFasesIds] = useState(null);

  const [tabKey, setTabKey] = useState(uuid().slice(4));
  const [actionsButtons, setActionsButtons] = useState(controller.makeActionsButtons());
  const [listaPreferencias, setListaPreferencias] = useState([]);
  const [visibilidadePreferencias, setVisibilidadePreferencias] = useState({});
  const [fasesTimeline, setFasesTimeline] = useState([]);

  const mutableSelector = useSelector(controller.makeMuttableSelectors);
  const immutableSelectors = useSelector(controller.makeImmutableSelectors);
  const loadingSelectors = useSelector(controller.makeLoadingSelectors);
  const [cliente, setCliente] = useState(null);
  const [devedor, setDevedor] = useState(null);
  const [devedorWarnings, setDevedorWarnings] = useState(null);
  const [clientePermiteUnificar, setClientePermiteUnificar] = useState(false);
  const [dadosProcesso, setDadosProcesso] = useState({});
  const [redirect, setRedirect] = useState('');
  const [disabledAntecedentes, setDisabledAntecedentes] = useState(false);

  const [loadings, setLoadings] = useState(loadingController.makeLoadings());

  const hasIdsProcessos = useCallback(() => !idsProcessos && idsProcessos.length > 0, [idsProcessos]);

  function chooseIdProcesso(e, newValue) {
    if (hasIdsProcessos() && !idsProcessos[newValue]) {
      return;
    }
    setTabKey(uuid().slice(4));
    setCurrentTabSelected({ tabIndex: newValue, processo: { id: idsProcessos[newValue], key: uuid() } });
  }

  function runActionButtonByTag(tag) {
    return () => {
      setActionsButtons(controller.changeActionButton(actionsButtons, tag));
    };
  }

  const updatePage = useCallback(() => {
    acompanhamentoRef.current.moveScrollToTop();
    setCurrentTabSelected({ ...currentTabSelected, processo: { ...currentTabSelected.processo, key: uuid() } });
    setActionsButtons(controller.makeActionsButtons(additionalInfos));
  }, [currentTabSelected, additionalInfos]);

  const getActionProps = useCallback((action) => {
    const defaultProps = {
      processo: currentTabSelected.processo,
      setLoadings,
      updatePage,
    };

    return actionProps[action.tag].makeActionProps(defaultProps, {
      tiposAcompanhamento: immutableSelectors.tiposAcompanhamento,
      tiposAgendamentos: immutableSelectors.tiposAgendamentos,
      requisicoes: immutableSelectors.requisicoes,
      podeModificarFase: additionalInfos.podeModificarFase,
      temPermissaoAtenderRequisicao: !additionalInfos?.temPermissaoAtenderRequisicao,
      motivosBaixa: immutableSelectors.motivosBaixa,
      tipoTitulo: immutableSelectors.tipoTitulo,
      permiteCobrancaPresencial: additionalInfos?.sinalizacaoClienteProcessoQuery.permiteCobrancaPresencial,
      fases,
      idFasePastaAtual: additionalInfos.idFasePasta || '',
      temAgendamento: additionalInfos.temAgendamento,
      idFaseAtual: Number(additionalInfos.idFase),
      idMotivoBaixa: additionalInfos?.idMotivoBaixa || '',
      idNegociador: additionalInfos?.idNegociador || '',
      idProcesso: currentTabSelected.processo.id,
      tiposTitulosAgendamentos: immutableSelectors.tiposTitulosAgendamentos,
      setLoadings,
      clientePermiteUnificar,
      dadosProcesso,
      updatePage,
      fasesIds,
      disabledAntecedentes,
      podeReenviarWhatsapp: additionalInfos?.podeReenviarWhatsapp || '',
    });
  }, [
    currentTabSelected,
    immutableSelectors,
    additionalInfos,
    fases,
    updatePage, clientePermiteUnificar, dadosProcesso, fasesIds, disabledAntecedentes,
  ]);

  const loadListaPreferencias = useCallback(async () => {
    setLoadings(loadingController.changeLoadingListaPreferencias(true));

    const res = await controller.findPreferencias();
    setListaPreferencias(res.preferencias);
    setVisibilidadePreferencias(res.visibilidadePreferencias);

    setLoadings(loadingController.changeLoadingListaPreferencias(false));
  }, []);

  const tabsIdsProcessos = useMemo(() => {
    const items = controller.makeIdsProcessosTab(idsProcessos);
    if (items.length === 0) {
      return [];
    }
    return items;
  }, [idsProcessos]);

  const loading = useMemo(() => controller.isLoading(loadings), [loadings]);

  // function voltarParaPaginaAnterior() {
  // const previousUrl = localStorage.getItem('previousUrl');
  // if (previousUrl) {
  //  localStorage.removeItem('previousUrl'); // Remova a URL armazenada para evitar reutilização acidental
  //  history.push(previousUrl);
  // } else {
  // Caso não haja URL anterior, você pode redirecionar para uma página padrão ou tratar conforme necessário.
  // history.push('/processos');
  // }
  // }

  /**
   * Carregas processos que serão exibidos para serem selecionados
   */
  useEffect(() => {
    const idProcessoQuery = new URLSearchParams(history.location.search).get('nroProcesso');
    let ids = [];
    if (idProcessoQuery) {
      ids = [Number(idProcessoQuery)];
    } else {
      const idsProcessoSession = sessionStorage.getItem('idsProcessos');
      ids = JSON.parse(idsProcessoSession);
    }

    if (ids?.length > 0) {
      setIdsProcessos(ids);
      setCurrentTabSelected({ tabIndex: 0, processo: { id: ids[0], key: uuid() } });
    } else {
      history.replace('/processos');
    }
  }, [history]);

  /**
   * Carregar informações que dependem do id do processo
   */
  useEffect(() => {
    if (!currentTabSelected.processo.id) {
      return;
    }

    setLoadings(loadingController.changeLoadingProcessoInfo(true));
    controller.loadMutableInfo(currentTabSelected.processo.id).then((res) => {
      setCliente(res.cliente);
      setDevedor(res.devedor);
      setDevedorWarnings(res.devedorWarnings);
      setAdditionalInfos(res.infoProcesso);
      setActionsButtons(res.actionsButtons);
      setFasesTimeline(res.fasesTimeline);
      loadListaPreferencias().then();
      setClientePermiteUnificar(res.infoProcesso.permitirUnificarProcesso);
      setDadosProcesso(res.dadosProcesso);
    }).finally(() => {
      setLoadings(loadingController.changeLoadingProcessoInfo(false));
    });
  }, [dispatch, currentTabSelected, loadListaPreferencias]);

  /**
   * Carregar informações que somente serão inicializadas uma unica vez
   */
  useEffect(() => {
    dispatch(initializePageAction('Inspecionar'));

    setLoadings(loadingController.changeLoadingStartPage(true));
    controller.loadImmutableInfo().then((res) => {
      setFases(res.fases);
    }).finally(() => {
      setLoadings(loadingController.changeLoadingStartPage(false));
    });
  }, [dispatch, loadListaPreferencias]);

  useEffect(() => {
    const fetchFasesData = async () => {
      try {
        const responseFaseBaixa = await buscarIdFaseBaixa();
        const responseFaseAcordoSeguro = await buscarIdFaseAcordoSeguro();

        setFasesIds({
          idFaseAcordoSeguro: responseFaseAcordoSeguro.data,
          idFaseBaixa: responseFaseBaixa.data,
        });
      } catch (error) {
        toast.error(error.response.data);
      }
    };

    fetchFasesData();
  }, []);

  useEffect(() => {
    const previousUrl = localStorage.getItem('previousUrl');
    setRedirect(previousUrl);
  }, [redirect]);

  return (
    <>
      <LoadingUI show={loadingSelectors || loading} />
      <HeaderContainer>
        <PreferenciasProcesso
          listaPreferencias={listaPreferencias}
          loadListaPreferencias={loadListaPreferencias}
          setLoadings={setLoadings}
        />
      </HeaderContainer>
      <MainContainer>
        <TabsUi items={tabsIdsProcessos} value={currentTabSelected.tabIndex} onChange={chooseIdProcesso} />
        <AdditionalInfoContainer>
          <div style={{ display: 'flex', width: '100%' }}>
            <SinalizacaoCores
              idProcesso={currentTabSelected?.processo?.id}
              sinalizacaoCoresQuery={additionalInfos?.sinalizacaoCoresQuery}
              podeModificarFase={additionalInfos?.podeModificarFase}
              setLoadings={setLoadings}
              setAdictionalInfos={setAdditionalInfos}
              permitirAjuizarProcesso={additionalInfos?.permitirAjuizarProcesso}
            />
            <SinalizacaoAlertas
              sinalizacaoAlertasQuery={additionalInfos?.sinalizacaoAlertasQuery}
            />
            <SinalizacaoIcones
              idProcesso={currentTabSelected?.processo?.id}
              sinalizacaoClienteProcessoQuery={additionalInfos?.sinalizacaoClienteProcessoQuery}
              podeModificarFase={additionalInfos?.podeModificarFase}
              setLoadings={setLoadings}
              setAdictionalInfos={setAdditionalInfos}
            />
          </div>
        </AdditionalInfoContainer>
        <Container>
          <ActionContainer>
            <ActionButtonGroup>
              {actionsButtons.map(actions => (
                <div key={actions.key}>
                  <TooltipUI title={actions.label} placement="bottom">
                    <div>
                      <ActionButton
                        active={actions.active.toString()}
                        disabled={actions.disabled}
                        onClick={runActionButtonByTag(actions.tag)}
                      >
                        <actions.Icon size={16} style={actions.styles} />
                      </ActionButton>
                    </div>
                  </TooltipUI>
                </div>
              ))}
            </ActionButtonGroup>
            {actionsButtons.filter(action => action.active === true).map(action => (
              <ActionContentContainer key={action.tag}>
                <action.Content {...getActionProps(action)} />
              </ActionContentContainer>
            ))}
          </ActionContainer>
          <Accordions>
            {visibilidadePreferencias[1] && (
              <Accordion
                key={`${tabKey}-acompanhamento`}
                defaultExpanded
                color="purePurple"
                title="Acompanhamento"
              >
                <AccordionDetails>
                  <Acompanhamento
                    ref={acompanhamentoRef}
                    processo={currentTabSelected.processo}
                    numeroDias={additionalInfos.numeroDiasDataAcompanhamento}
                    podeModificarFase={additionalInfos.podeModificarFase}
                    autores={mutableSelector.autoresAcompanhamentos}
                    fases={fases}
                    tiposAcompanhamento={immutableSelectors.tiposAcompanhamento}
                    setLoadings={setLoadings}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            {visibilidadePreferencias[2] && (
              <Accordion
                key={`${tabKey}-resumo`}
                defaultExpanded={false}
                color="purePurple"
                title="Resumo"
              >
                <AccordionDetails>
                  <ResumoProcesso
                    processo={currentTabSelected.processo}
                    setLoadings={setLoadings}
                    dadosProcesso={dadosProcesso}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            {visibilidadePreferencias[3] && (
              <Accordion
                key={`${tabKey}-cliente`}
                defaultExpanded={false}
                color="purePurple"
                title="Dados do Cliente"
              >
                <AccordionDetails>
                  <DadosClienteProcesso
                    cliente={cliente}
                    podeModificarFase={additionalInfos?.podeModificarFase}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            {visibilidadePreferencias[4] && (
              <Accordion
                key={`${tabKey}-devedor`}
                defaultExpanded={false}
                color="purePurple"
                title="Dados do Devedor"
                icon={
                  devedorWarnings.length > 0 && (
                    <WarningIcon style={{
                      color: '#FFA000', fontSize: '20px', marginLeft: '6px',
                    }}
                    />
                  )
                }
                iconTitle={devedorWarnings.length > 0 ? (
                  <>
                    <p>
                      Para registrar um boleto:
                      {' '}
                      <br />
                    </p>
                    <ul>
                      {devedorWarnings.map((warning, idx) => (
                        <li key={idx}>
                          -
                          {' '}
                          {warning}
                        </li>
                      ))}
                    </ul>
                  </>
                ) : ''}
              >
                <AccordionDetails>
                  <DadosDevedorProcesso
                    devedor={devedor}
                    podeModificarFase={additionalInfos?.podeModificarFase}
                    immutableSelectors={immutableSelectors}
                    idProcesso={currentTabSelected.processo.id}
                    setLoadings={setLoadings}
                    updatePage={updatePage}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            {visibilidadePreferencias[6] && (
              <Accordion
                key={`${tabKey}-titulos`}
                defaultExpanded={false}
                color="purePurple"
                title="Títulos"
              >
                <AccordionDetails>
                  <TitulosProcesso
                    processo={currentTabSelected.processo}
                    setLoadings={setLoadings}
                    idFaseProcesso={additionalInfos.idFase}
                    sinalizacaoCoresQuery={additionalInfos?.sinalizacaoCoresQuery}
                    updatePage={updatePage}
                    immutableSelectors={immutableSelectors}
                    additionalInfos={additionalInfos.estadoDoDevedor}
                    fasesIds={fasesIds}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            {visibilidadePreferencias[10] && (
              <Accordion
                key={`${tabKey}-recalculo-processo`}
                defaultExpanded={false}
                color="purePurple"
                title="Recálculos do Processo"
              >
                <AccordionDetails>
                  <ListaRecalculoProcesso
                    processoIdsWithUpdater={currentTabSelected.processo}
                    setLoadings={setLoadings}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            {visibilidadePreferencias[5] && (
              <Accordion
                key={`${tabKey}-antecedente`}
                defaultExpanded={false}
                color="purePurple"
                title="Antecedentes"
              >
                <AccordionDetails>
                  <AntecedenteProcesso
                    idProcesso={currentTabSelected.processo.id}
                    setLoadings={setLoadings}
                    clientePermiteUnificar={clientePermiteUnificar}
                    dadosProcesso={dadosProcesso}
                    updatePage={updatePage}
                    podeModificarFase={additionalInfos?.podeModificarFase}
                    idFaseProcesso={additionalInfos.idFase}
                    fasesIds={fasesIds}
                    accordion
                    setDisabledAntecedentes={setDisabledAntecedentes}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            {visibilidadePreferencias[7] && (
              <Accordion
                key={`${tabKey}-fases`}
                defaultExpanded={false}
                color="purePurple"
                title="Fases"
              >
                <AccordionDetails>
                  <FaseProcesso fasesTimeline={fasesTimeline} />
                </AccordionDetails>
              </Accordion>
            )}
            {visibilidadePreferencias[12] && (
            <Accordion
              key={`${tabKey}-dados-juridicos`}
              defaultExpanded={false}
              color="purePurple"
              title="Dados Jurídicos"
            >
              <AccordionDetails>
                <DadosJuridicos
                  idProcesso={currentTabSelected.processo.id}
                  cobrancaJudicial={additionalInfos?.sinalizacaoCoresQuery?.cobrancaJudicial}
                  updatePage={updatePage}
                />
              </AccordionDetails>
            </Accordion>
            )}
            {visibilidadePreferencias[8] && (
              <Accordion
                key={`${tabKey}-documento-processo`}
                defaultExpanded={false}
                color="purePurple"
                title="Documentos do Processo"
              >
                <AccordionDetails>
                  <DocumentosProcesso
                    idProcesso={currentTabSelected.processo.id}
                    podeModificarFase={additionalInfos?.podeModificarFase}
                    setLoadings={setLoadings}
                    updatePage={updatePage}
                  />
                </AccordionDetails>
              </Accordion>
            )}


            {visibilidadePreferencias[11] && (
              <Accordion
                key={`${tabKey}-acordos`}
                defaultExpanded={false}
                color="purePurple"
                title="Acordos"
              >
                <AccordionDetails>
                  <Acordos
                    podeModificarFase={additionalInfos?.podeModificarFase}
                    setLoadings={setLoadings}
                    idProcesso={currentTabSelected.processo.id}
                    idFaseProcesso={additionalInfos.idFase}
                    dadosProcesso={dadosProcesso}
                    sinalizacaoCoresQuery={additionalInfos?.sinalizacaoCoresQuery}
                    updatePage={updatePage}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            {visibilidadePreferencias[9] && (
              <Accordion
                key={`${tabKey}-ficha-financeira`}
                defaultExpanded={false}
                color="purePurple"
                title="Ficha Financeira"
              >
                <AccordionDetails>
                  <FichaFinanceira
                    setLoadings={setLoadings}
                    idProcesso={currentTabSelected.processo.id}
                  />
                </AccordionDetails>
              </Accordion>
            )}


          </Accordions>
        </Container>
      </MainContainer>
      {redirect !== '' ? (
        <FooterInspecionar>
          <ButtonUI onClick={() => history.replace(redirect)}>Voltar</ButtonUI>
        </FooterInspecionar>
      ) : (
        <FooterInspecionar>
          <ButtonUI onClick={() => history.replace('/processos')}>Voltar</ButtonUI>
        </FooterInspecionar>
      )}

    </>
  );
}

export const InspecionarProcessoPage = InspecionarProcessoPageComponent;
