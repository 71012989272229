import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  titleReenviarWhatsapp: {
    color: '#034DDC',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  formReenviarWhatsapp: {
    marginTop: '20px',
  },
  negociadorAtual: {
    fontSize: '14px',
    marginBottom: '15px',

    '& span:first-child': {
      fontWeight: 700,
    },

    '& span:last-child': {
      fontWeight: 600,
      marginLeft: '8px',
    },
  },
  footerReenviarWhatsapp: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  infos__gerais: {
    marginBottom: '16px',
    fontSize: '12px',
  },
});
